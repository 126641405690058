const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/phone/components/TabLabel.tsx";import { Icon } from "@opendash/icons";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";


const TabLabelContainer = styled.div`
  text-align: center;
  // padding: 0 4px;
  // width: 20vw;
  // max-width: 200px;

  .anticon {
    margin-right: 0 !important;
    font-size: 20px;
  }

  .tablabel {
    font-size: 10px;
  }
`;

export default observer(function TabLabel({
  menuitem: menuitem,
}) {
  return (
    React.createElement(TabLabelContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
      , React.createElement(Icon, { icon: menuitem.iconKey, title: menuitem.label, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} )
      , React.createElement('div', { className: "tablabel", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}, menuitem.label)
    )
  );
});
