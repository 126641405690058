import Parse from "parse";





















export class Item extends Parse.Object {
  static __initStatic() {this.className = "Heinzerling2_Item"}

  constructor(data) {
    super("Heinzerling2_Item", data );
  }

  get description4() {
    return super.get("description4");
  }
  set description4(value) {
    super.set("description4", value);
  }
  get inAutofill() {
    return super.get("inAutofill");
  }
  set inAutofill(value) {
    super.set("inAutofill", value);
  }
  get inPlanView() {
    return super.get("inPlanView");
  }
  set inPlanView(value) {
    super.set("inPlanView", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get no() {
    return super.get("no");
  }
  set no(value) {
    super.set("no", value);
  }
  get resource() {
    return super.get("resource");
  }
  set resource(value) {
    super.set("resource", value);
  }
  get searchIndex() {
    return super.get("searchIndex");
  }
  set searchIndex(value) {
    super.set("searchIndex", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted() {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value) {
    super.set("sqlDeleted", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
  get unit() {
    return super.get("unit");
  }
  set unit(value) {
    super.set("unit", value);
  }
} Item.__initStatic();

Parse.Object.registerSubclass("Heinzerling2_Item", Item);
