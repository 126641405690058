const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/items/components/PartsListDrawer.tsx";import { Icon } from "@opendash/icons";
import { Button, Drawer, List, Space } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { UpdateItemDrawer } from "..";


const Quantity = styled.span`
  width: 40px;
  // text-align: right;
  display: inline-block;
`;

export default observer(({ state }) => {
  // Items should not be added to parts lists currently.
  // Functionality will be commented and can be implemented if needed.
  // const additemstate = useMemo(() => new AddItemState(state.orderstate), []);

  return (
    React.createElement(Drawer, {
      title: `Stückliste für "${state.name}"`,
      placement: "bottom",
      open: !!state.open,
      onClose: () => (state.open = false),
      height: "90%",
      extra: 
        React.createElement(Space, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
          , React.createElement(Button, { children: "Schließen", onClick: () => (state.open = false), __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}} )

          , React.createElement(Button, {
            children: "Alles in die Zwischenablage kopieren"    ,
            onClick: () => state.copyPartsListToClipboard(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
          )
          /* <Button
          type="primary"
          disabled={state.disabled}
          children="Artikel hinzufügen"
          onClick={() => (additemstate.open = true)}
        /> */
        )
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}

      , React.createElement(List, {
        dataSource: state.parts,
        renderItem: (ir) => {
          return (
            React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
              , React.createElement(Space, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
                , React.createElement(Quantity, { children: state.getPartQuantity(ir), __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}} )
                , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}, state.getPartTitle(ir))
              )
              , React.createElement(Space, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
                , React.createElement(Button, {
                  disabled: state.disabled,
                  onClick: () => state.setUpdateDialog(ir), __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}

                  , React.createElement(Icon, { icon: "fa:pencil", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}} )
                )
                , React.createElement(Button, {
                  disabled: state.disabled,
                  onClick: () => state.copyPartToClipboard(ir), __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}

                  , React.createElement(Icon, { icon: "fa:copy", __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}} )
                )
              )
            )
          );
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
      )

      /* <AddItemDrawer state={state} /> */

      , React.createElement(UpdateItemDrawer, { state: state.updateitemstate, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}} )
    )
  );
});
