import { HeinzerlingHelperService } from "./HeinzerlingHelperService";
import { HeinzerlingMobileService } from "./HeinzerlingMobileService";
import { HeinzerlingOfflineService } from "./HeinzerlingOfflineService";

export class HeinzerlingService {constructor() { HeinzerlingService.prototype.__init.call(this);HeinzerlingService.prototype.__init2.call(this);HeinzerlingService.prototype.__init3.call(this); }
   __init() {this.sync = new HeinzerlingOfflineService(this)}
   __init2() {this.mobile = new HeinzerlingMobileService(this)}
   __init3() {this.helper = new HeinzerlingHelperService(this)}

  init() {
    console.log("$heinzerling.init()");
  }
}

export const $heinzerling = new HeinzerlingService();
