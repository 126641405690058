const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/phone/components/UpdatePhotosTab.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Button, Divider, Image, List } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";


export default observer(function UpdatePhotosTab({
  state,
}) {
  if (!state.order) throw new Error("Invalid State");

  const ref = React.useRef(null);

  const [, setReload] = React.useState(0);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Image.PreviewGroup, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
        , React.createElement(List, {
          grid: { gutter: 16, column: 2 },
          dataSource: state.images,
          renderItem: (image) => (
            React.createElement(List.Item, { style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
              , React.createElement(Image, {
                src: image.thumbnail,
                title: image.name,
                width: "100%",
                preview: {
                  src: _optionalChain([image, 'access', _ => _.image, 'optionalAccess', _2 => _2.url, 'call', _3 => _3()]) || image.imageBase64,
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
              )

              , React.createElement(Button, {
                disabled: state.disabled,
                danger: true,
                children: "Löschen",
                style: { width: "100%", marginTop: 8 },
                onClick: () => {
                  state.destroyImage(image);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
              )
            )
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
        )
      )

      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 46}} )

      , React.createElement(Button, {
        disabled: state.disabled,
        type: "primary",
        children: "Foto hochladen" ,
        onClick: () => {
          if (ref.current) {
            ref.current.click();
          }
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
      )
      , React.createElement('input', {
        ref: ref,
        type: "file",
        multiple: true,
        accept: "image/png,image/gif,image/jpeg",
        style: { display: "none" },
        onChange: (e) => {
          if (e.target.files) {
            state
              .uploadImages(Array.from(e.target.files))
              .then(() => setReload((x) => x + 1));
          }
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
      )
    )
  );
});
