const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/phone/components/ContactPersonTab.tsx";import { Icon } from "@opendash/icons";
import { Button, List } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";


export default observer(function ContactPersonTab({
  state,
}) {
  const contacts = React.useMemo(
    () =>
      state.contacts.flatMap((contact) => {
        return [contact.phone, contact.mobile].filter(Boolean).map((nr) => {
          return {
            key: contact.id + "---" + nr,
            nr,
            title: contact.name,
            desciption: nr,
          };
        });
      }),
    [state.contacts]
  );

  return (
    React.createElement(List, {
      dataSource: contacts,
      renderItem: (contact) => (
        React.createElement(List.Item, {
          actions: [
            React.createElement(Button, {
              onClick: () => {
                window.open(`tel:${contact.nr}`);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}

              , React.createElement(Icon, { icon: "fa:phone", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}} )
            ),
          ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}

          , React.createElement(List.Item.Meta, {
            title: contact.title,
            description: contact.desciption, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
          )
        )
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
    )
  );
});
