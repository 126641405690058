const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/phone/components/UpdateTechnicansTab.tsx"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Icon } from "@opendash/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  InputNumber,
  List,
  Select,
  Space,
} from "antd";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

import { formatDate } from "../../../helper/formatter";
import { UpdateTechnicansState } from "../states/UpdateTechnicansState";

const HoursDiv = styled.span`
  width: 40px;
  // text-align: right;
  display: inline-block;

  margin-right: 10px;
`;

const IconsDiv = styled.span`
  margin: 0 10px;
  font-size: 14px;
`;

export default observer(function UpdateTechnicansTab({
  state,
}) {
  if (!state.order) throw new Error("Invalid State");

  const updateTechnicianState = React.useMemo(
    () => new UpdateTechnicansState(state),
    []
  );

  return (
    React.createElement(React.Fragment, null
      , React.createElement(List, {
        dataSource: updateTechnicianState.list,
        renderItem: (tr) => {
          const title = tr.technican.name || "";

          const hours = (_nullishCoalesce(tr.local_hours, () => ( tr.hours))) + " Std";

          return (
            React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
              , React.createElement(HoursDiv, { children: hours, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}} )
              , React.createElement(List.Item.Meta, {
                title: title,
                description: formatDate(tr.start), __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
              )

              , React.createElement(IconsDiv, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
                , tr.outsideBusinessHours && (
                  React.createElement(Icon, {
                    icon: "fa:moon",
                    color: "#cf942a",
                    title: "Außerhalb der Geschäftszeiten"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
                  )
                )

                , tr.local_hideFromCustomer && (
                  React.createElement(Icon, {
                    icon: "fa:eye-slash",
                    color: "#802acf",
                    title: "Nicht für den Kunden sichtbar"    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
                  )
                )
              )
              , React.createElement(Button, {
                disabled: state.disabled,
                onClick: () => updateTechnicianState.setUpdateDialog(tr), __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}

                , React.createElement(Icon, { icon: "fa:pencil", __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}} )
              )
            )
          );
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
      )

      , React.createElement(IconsDiv, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
        , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
          , React.createElement(Icon, { icon: "fa:moon", color: "#cf942a", __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}} ), " - Außerhalb der Geschäftszeiten"

        )
        , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
          , React.createElement(Icon, { icon: "fa:eye-slash", color: "#802acf", __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}} ), " - Für den Kunden nicht sichtbar"

        )
      )

      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 101}} )

      , React.createElement(Button, {
        type: "primary",
        disabled: state.disabled,
        children: "Neue Stunden hinzufügen"  ,
        onClick: () => updateTechnicianState.create(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}
      )

      , React.createElement(Drawer, {
        title: "Artikel bearbeiten" ,
        placement: "bottom",
        open: !!updateTechnicianState.updateDialog,
        onClose: () => updateTechnicianState.setUpdateDialog(null),
        height: "560px", __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}

        , React.createElement(Form, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
          , React.createElement(Form.Item, {
            label: "Monteur",
            validateStatus: 
              updateTechnicianState.updateDialogTechnican === null
                ? "error"
                : "success"
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}

            , React.createElement(Select, {
              disabled: !!_optionalChain([updateTechnicianState, 'access', _ => _.updateDialog, 'optionalAccess', _2 => _2.sqlRef]),
              showSearch: true,
              optionFilterProp: "children",
              placeholder: "Wähle einen Monteur aus"   ,
              options: updateTechnicianState.technicanOptions,
              value: _optionalChain([updateTechnicianState, 'access', _3 => _3.updateDialogTechnican, 'optionalAccess', _4 => _4.id]),
              onChange: (value) => {
                updateTechnicianState.setUpdateDialogTechnican(value);
              },
              filterOption: (input, option) =>
                (_nullishCoalesce(_optionalChain([option, 'optionalAccess', _5 => _5.label]), () => ( "")))
                  .toLowerCase()
                  .includes(input.toLowerCase())
              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}
            )
          )
          , React.createElement(Form.Item, {
            label: "Datum",
            validateStatus: 
              updateTechnicianState.updateDialogDate === null
                ? "error"
                : "success"
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 143}}

            , React.createElement(DatePicker, {
              disabled: !!_optionalChain([updateTechnicianState, 'access', _6 => _6.updateDialog, 'optionalAccess', _7 => _7.sqlRef]),
              value: 
                updateTechnicianState.updateDialogDate
                  ? dayjs(updateTechnicianState.updateDialogDate)
                  : undefined
              ,
              onChange: (value) => {
                updateTechnicianState.setUpdateDialogDate(
                  _optionalChain([value, 'optionalAccess', _8 => _8.toDate, 'call', _9 => _9()]) || null
                );
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
            )
          )
          , React.createElement(Form.Item, {
            label: "Stunden",
            validateStatus: 
              updateTechnicianState.updateDialogHours === null
                ? "error"
                : "success"
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 165}}

            , React.createElement(InputNumber, {
              autoFocus: true,
              value: updateTechnicianState.updateDialogHours,
              decimalSeparator: ",",
              onChange: (value) => {
                updateTechnicianState.setUpdateDialogHours(value);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 173}}
            )
          )
          , React.createElement(Form.Item, {
            label: "Sonstiges",
            validateStatus: 
              updateTechnicianState.updateDialogOutsideBusinessHours === null
                ? "error"
                : "success"
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 182}}

            , React.createElement(Checkbox, {
              checked: updateTechnicianState.updateDialogOutsideBusinessHours,
              onChange: (e) => {
                updateTechnicianState.setUpdateDialogOutsideBusinessHours(
                  e.target.checked
                );
              },
              children: "Die Arbeiten fanden außerhalb der Geschäftszeiten statt"      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 190}}
            )

            , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 200}} )

            , React.createElement(Checkbox, {
              checked: updateTechnicianState.updateDialogHideFromCustomer,
              onChange: (e) => {
                updateTechnicianState.setUpdateDialogHideFromCustomer(
                  e.target.checked
                );
              },
              children: "Für den Kunden nicht sichtbar"    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 202}}
            )
          )
          , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 212}}
            , React.createElement(Space, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 213}}
              , React.createElement(Button, {
                type: "primary",
                children: "Speichern",
                onClick: () => {
                  updateTechnicianState.saveUpdateDialog();
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 214}}
              )
              , React.createElement(Button, {
                danger: true,
                type: "primary",
                children: "Löschen",
                onClick: () => {
                  updateTechnicianState.destoryUpdateDialog();
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 221}}
              )
            )
          )
        )
      )
    )
  );
});
