const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/items/components/PartsListEntry.tsx"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Icon } from "@opendash/icons";
import { Button, Space } from "antd";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import styled from "styled-components";
import {
  PartsListDrawer,

  PartsListState,
  UpdateItemDrawer,
  UpdateItemState,
} from "..";

const Quantity = styled.span`
  width: 40px;
  display: inline-block;
`;

export default observer(({ oir, orderstate }) => {
  const partsliststate = useMemo(() => new PartsListState(oir, orderstate), []);
  const updateitemstate = useMemo(() => new UpdateItemState(orderstate), []);

  let title = "";

  if (typeof oir.item === "undefined") {
    title = oir.description;
  }

  if (typeof oir.item !== "undefined" && title.length === 0) {
    title = oir.item.name || oir.item.description4;
  }

  // If this is a dummy then display the description from the order
  if (title.toLowerCase() === "dummy") {
    title = oir.description;
  }

  const quantity =
    (_nullishCoalesce(_nullishCoalesce(updateitemstate.quantity, () => ( oir.local_quantity)), () => ( oir.quantity))) +
    " " +
    _optionalChain([oir, 'access', _ => _.item, 'optionalAccess', _2 => _2.unit]);
  return (
    React.createElement(React.Fragment, null
      , React.createElement(Space, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
        , React.createElement(Quantity, { children: quantity, __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}} )
        , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}, title)
      )

      , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        , React.createElement(Space, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
          , partsliststate.showPartsList(oir) ? (
            React.createElement(Button, {
              onClick: () => {
                partsliststate.name = title;
                partsliststate.open = true;
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}

              , React.createElement(Icon, { icon: "fa:list-ol", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}} )
            )
          ) : null
          , oir.item || null ? (
            React.createElement(Button, {
              disabled: orderstate.disabled,
              onClick: () => {
                updateitemstate.itemrelation = oir;
                updateitemstate.open = true;
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}

              , React.createElement(Icon, { icon: "fa:pencil", __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}} )
            )
          ) : null
        )
      )
      , React.createElement(PartsListDrawer, { state: partsliststate, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}} )

      , React.createElement(UpdateItemDrawer, { state: updateitemstate, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}} )
    )
  );
});
