import Parse from "parse";































export class OrderTechnicanRelation extends Parse.Object {
  static __initStatic() {this.className = "Heinzerling2_OrderTechnicanRelation"}

  constructor(data) {
    super("Heinzerling2_OrderTechnicanRelation", data );
  }

  get category() {
    return super.get("category");
  }
  set category(value) {
    super.set("category", value);
  }
  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get end() {
    return super.get("end");
  }
  set end(value) {
    super.set("end", value);
  }
  get facility() {
    return super.get("facility");
  }
  set facility(value) {
    super.set("facility", value);
  }
  get fromSync() {
    return super.get("fromSync");
  }
  set fromSync(value) {
    super.set("fromSync", value);
  }
  get hours() {
    return super.get("hours");
  }
  set hours(value) {
    super.set("hours", value);
  }
  get local_deleted() {
    return super.get("local_deleted");
  }
  set local_deleted(value) {
    super.set("local_deleted", value);
  }
  get local_hideFromCustomer() {
    return super.get("local_hideFromCustomer");
  }
  set local_hideFromCustomer(value) {
    super.set("local_hideFromCustomer", value);
  }
  get local_hours() {
    return super.get("local_hours");
  }
  set local_hours(value) {
    super.set("local_hours", value);
  }
  get order() {
    return super.get("order");
  }
  set order(value) {
    super.set("order", value);
  }
  get outsideBusinessHours() {
    return super.get("outsideBusinessHours");
  }
  set outsideBusinessHours(value) {
    super.set("outsideBusinessHours", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted() {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value) {
    super.set("sqlDeleted", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
  get start() {
    return super.get("start");
  }
  set start(value) {
    super.set("start", value);
  }
  get sys_app_id() {
    return super.get("sys_app_id");
  }
  set sys_app_id(value) {
    super.set("sys_app_id", value);
  }
  get technican() {
    return super.get("technican");
  }
  set technican(value) {
    super.set("technican", value);
  }
} OrderTechnicanRelation.__initStatic();

Parse.Object.registerSubclass("Heinzerling2_OrderTechnicanRelation", OrderTechnicanRelation);
