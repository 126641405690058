import { makeAutoObservable, runInAction } from "@opendash/state";
import { message } from "antd";

import { $heinzerling } from "../../../service";

export class UpdateTextsState {
  
   __init() {this.finishedText = ""}
   __init2() {this.documentText = ""}
   __init3() {this.local_moreWorkNecessary = false}
   __init4() {this.local_emergencyService = false}
   __init5() {this.local_vandalism = false}
   __init6() {this.local_overvoltageDamage = false}

   __init7() {this.dirty = false}

   __init8() {this.insertTemplate = null}

  constructor(order) {;UpdateTextsState.prototype.__init.call(this);UpdateTextsState.prototype.__init2.call(this);UpdateTextsState.prototype.__init3.call(this);UpdateTextsState.prototype.__init4.call(this);UpdateTextsState.prototype.__init5.call(this);UpdateTextsState.prototype.__init6.call(this);UpdateTextsState.prototype.__init7.call(this);UpdateTextsState.prototype.__init8.call(this);
    this.order = order;

    makeAutoObservable(this);

    this.reset();
  }

  setFinishedText(v) {
    this.finishedText = v;
    this.dirty = true;
  }

  setDocumentText(v) {
    this.documentText = v;
    this.dirty = true;
  }

  setInstertTemplate(v) {
    this.insertTemplate = v;
  }

  instertTemplateText(v) {
    if (this.insertTemplate === "finishedText") {
      this.finishedText += v;
    }

    if (this.insertTemplate === "documentText") {
      this.documentText += v;
    }

    this.insertTemplate = null;
    this.dirty = true;
  }

   reset() {
    this.finishedText = this.order.local_finishedText || "";
    this.documentText = this.order.local_documentText || "";
    this.local_moreWorkNecessary = this.order.local_moreWorkNecessary || false;
    this.local_emergencyService = this.order.local_emergencyService || false;
    this.local_vandalism = this.order.local_vandalism || false;
    this.local_overvoltageDamage = this.order.local_overvoltageDamage || false;
    this.dirty = false;
  }

   async save() {
    try {
      this.order.set("local_finishedText", this.finishedText);
      this.order.set("local_documentText", this.documentText);
      this.order.set("local_moreWorkNecessary", this.local_moreWorkNecessary);
      this.order.set("local_emergencyService", this.local_emergencyService);
      this.order.set("local_vandalism", this.local_vandalism);
      this.order.set("local_overvoltageDamage", this.local_overvoltageDamage);

      await $heinzerling.sync.saveObject(this.order);

      runInAction(() => {
        this.dirty = false;
      });

      message.success("Gespeichert");
    } catch (error) {
      message.error("Speichern fehlgeschlagen");
    }
  }
}
