import Parse from "parse";











export class DeletedObjects extends Parse.Object {
  static __initStatic() {this.className = "Heinzerling2_DeletedObjects"}

  constructor(data) {
    super("Heinzerling2_DeletedObjects", data );
  }

  get c() {
    return super.get("c");
  }
  set c(value) {
    super.set("c", value);
  }
  get i() {
    return super.get("i");
  }
  set i(value) {
    super.set("i", value);
  }
} DeletedObjects.__initStatic();

Parse.Object.registerSubclass("Heinzerling2_DeletedObjects", DeletedObjects);
