import Parse from "parse";

















































export class Order extends Parse.Object {
  static __initStatic() {this.className = "Heinzerling2_Order"}

  constructor(data) {
    super("Heinzerling2_Order", data );
  }

  get closingText() {
    return super.get("closingText");
  }
  set closingText(value) {
    super.set("closingText", value);
  }
  get customer() {
    return super.get("customer");
  }
  set customer(value) {
    super.set("customer", value);
  }
  get date() {
    return super.get("date");
  }
  set date(value) {
    super.set("date", value);
  }
  get documentText() {
    return super.get("documentText");
  }
  set documentText(value) {
    super.set("documentText", value);
  }
  get facility() {
    return super.get("facility");
  }
  set facility(value) {
    super.set("facility", value);
  }
  get finishedText() {
    return super.get("finishedText");
  }
  set finishedText(value) {
    super.set("finishedText", value);
  }
  get introductionText() {
    return super.get("introductionText");
  }
  set introductionText(value) {
    super.set("introductionText", value);
  }
  get isMaintenance() {
    return super.get("isMaintenance");
  }
  set isMaintenance(value) {
    super.set("isMaintenance", value);
  }
  get isNewOrder() {
    return super.get("isNewOrder");
  }
  set isNewOrder(value) {
    super.set("isNewOrder", value);
  }
  get local_createdOnMobile() {
    return super.get("local_createdOnMobile");
  }
  set local_createdOnMobile(value) {
    super.set("local_createdOnMobile", value);
  }
  get local_documentText() {
    return super.get("local_documentText");
  }
  set local_documentText(value) {
    super.set("local_documentText", value);
  }
  get local_emergencyService() {
    return super.get("local_emergencyService");
  }
  set local_emergencyService(value) {
    super.set("local_emergencyService", value);
  }
  get local_finishedText() {
    return super.get("local_finishedText");
  }
  set local_finishedText(value) {
    super.set("local_finishedText", value);
  }
  get local_mailto() {
    return super.get("local_mailto");
  }
  set local_mailto(value) {
    super.set("local_mailto", value);
  }
  get local_moreWorkNecessary() {
    return super.get("local_moreWorkNecessary");
  }
  set local_moreWorkNecessary(value) {
    super.set("local_moreWorkNecessary", value);
  }
  get local_overvoltageDamage() {
    return super.get("local_overvoltageDamage");
  }
  set local_overvoltageDamage(value) {
    super.set("local_overvoltageDamage", value);
  }
  get local_signatureCustomer() {
    return super.get("local_signatureCustomer");
  }
  set local_signatureCustomer(value) {
    super.set("local_signatureCustomer", value);
  }
  get local_signatureTechnican() {
    return super.get("local_signatureTechnican");
  }
  set local_signatureTechnican(value) {
    super.set("local_signatureTechnican", value);
  }
  get local_signatureTimestamp() {
    return super.get("local_signatureTimestamp");
  }
  set local_signatureTimestamp(value) {
    super.set("local_signatureTimestamp", value);
  }
  get local_vandalism() {
    return super.get("local_vandalism");
  }
  set local_vandalism(value) {
    super.set("local_vandalism", value);
  }
  get no() {
    return super.get("no");
  }
  set no(value) {
    super.set("no", value);
  }
  get orderText() {
    return super.get("orderText");
  }
  set orderText(value) {
    super.set("orderText", value);
  }
  get referenceContact() {
    return super.get("referenceContact");
  }
  set referenceContact(value) {
    super.set("referenceContact", value);
  }
  get referenceDate() {
    return super.get("referenceDate");
  }
  set referenceDate(value) {
    super.set("referenceDate", value);
  }
  get referenceNo() {
    return super.get("referenceNo");
  }
  set referenceNo(value) {
    super.set("referenceNo", value);
  }
  get releaseForInvoice() {
    return super.get("releaseForInvoice");
  }
  set releaseForInvoice(value) {
    super.set("releaseForInvoice", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted() {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value) {
    super.set("sqlDeleted", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
  get sys_locked() {
    return super.get("sys_locked");
  }
  set sys_locked(value) {
    super.set("sys_locked", value);
  }
  get sys_mailDelivered() {
    return super.get("sys_mailDelivered");
  }
  set sys_mailDelivered(value) {
    super.set("sys_mailDelivered", value);
  }
  get sys_mailSent() {
    return super.get("sys_mailSent");
  }
  set sys_mailSent(value) {
    super.set("sys_mailSent", value);
  }
  get sys_pdf() {
    return super.get("sys_pdf");
  }
  set sys_pdf(value) {
    super.set("sys_pdf", value);
  }
  get type() {
    return super.get("type");
  }
  set type(value) {
    super.set("type", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} Order.__initStatic();

Parse.Object.registerSubclass("Heinzerling2_Order", Order);
