const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/phone/components/OverviewTab.tsx";import { useTranslation } from "@opendash/i18n";
import { Descriptions, Divider, Table } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { DisplayText } from "../../../components/DisplayText";
import FacilityDescription from "../../../components/FacilityDescription";
import {
  formatAddress,
  formatDate,
  formatName,
} from "../../../helper/formatter";


export default observer(function OverviewTab({ state }) {
  const t = useTranslation();

  if (!state.order) return null;

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Descriptions, {
        title: "Auftragsinformationen",
        bordered: true,
        size: "small",
        style: { marginBottom: 30 },
        column: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}

        , React.createElement(Descriptions.Item, { label: "Art", children: state.order.type, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} )
        , React.createElement(Descriptions.Item, {
          label: "Datum",
          children: formatDate(state.order.date), __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
        )
        , React.createElement(Descriptions.Item, {
          label: "Kunde",
          children: formatName(state.order.customer), __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
        )
        , React.createElement(Descriptions.Item, {
          label: "Kundenanschrift",
          children: formatAddress(state.order.customer, true), __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
        )
        , React.createElement(Descriptions.Item, {
          label: t("app:classes.Order.fields.referenceContact"),
          children: state.order.referenceContact || "-", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        )
        , React.createElement(Descriptions.Item, {
          label: t("app:classes.Order.fields.referenceNo"),
          children: state.order.referenceNo || "-", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
        )
        , React.createElement(Descriptions.Item, {
          label: t("app:classes.Order.fields.referenceDate"),
          children: formatDate(state.order.referenceDate) || "-", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        )
      )

      , state.rel ? (
        React.createElement(React.Fragment, null
          , React.createElement('div', {
            className: "ant-descriptions-title",
            children: `${t(
              "app:classes.Order.fields.relationdescription"
            )} ${new Date(state.order.get("date")).toLocaleDateString(
              "de-de"
            )} um ${new Date(state.order.get("date")).toLocaleString("de-de", {
              hour: "2-digit",
              minute: "2-digit",
            })}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
          )

          , React.createElement(DisplayText, { text: state.relationdescription, fallback: "-", __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}} )
        )
      ) : null

      , React.createElement('div', {
        className: "ant-descriptions-title",
        children: t("app:classes.Order.fields.documentText"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
      )

      , React.createElement(DisplayText, { text: state.order.documentText, fallback: "-", __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}} )

      , React.createElement('div', {
        className: "ant-descriptions-title",
        children: t("app:classes.Order.fields.orderText"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
      )

      , React.createElement(DisplayText, { text: state.order.orderText, fallback: "-", __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}} )

      /* <div
        className="ant-descriptions-title"
        children={t("app:classes.Order.fields.finishedText")}
      />

      <DisplayText text={state.order.finishedText} fallback="-" /> */

      , React.createElement('div', {
        className: "ant-descriptions-title",
        children: t("app:classes.Order.fields.introductionText"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
      )

      , React.createElement(DisplayText, { text: state.order.introductionText, fallback: "-", __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}} )

      /* <div
        className="ant-descriptions-title"
        children={t("app:classes.Order.fields.closingText")}
      />

      <DisplayText text={state.order.closingText} fallback="-" /> */

      , React.createElement(Divider, { children: state.facilities.length > 1 ? "Aufzüge" : "Aufzug", __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}} )

      , state.facilities.map((f) => (
        React.createElement(FacilityDescription, { key: f.id, facility: f, __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}} )
      ))

      , React.createElement(Divider, { children: "Material", __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}} )

      , React.createElement(Table, {
        dataSource: state.getItemsSummary(),
        size: "small",
        pagination: false,
        columns: [
          {
            key: "position",
            dataIndex: "position",
            title: "Pos.",
          },
          {
            key: "name",
            dataIndex: "name",
            title: "Name",
          },
          {
            key: "quantity",
            dataIndex: "quantity",
            title: "Menge",
          },
          {
            key: "unit",
            dataIndex: "unit",
            title: "Einheit",
          },
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}
      )

      , React.createElement(Divider, { children: "Arbeiten", __self: this, __source: {fileName: _jsxFileName, lineNumber: 144}} )

      , React.createElement(Table, {
        dataSource: state.getTechnicansSummary(),
        size: "small",
        pagination: false,
        columns: [
          {
            key: "name",
            dataIndex: "name",
            title: "Name",
          },
          {
            key: "date",
            dataIndex: "date",
            title: "Datum",
          },
          {
            key: "hours",
            dataIndex: "hours",
            title: "Stunden",
          },
          {
            key: "other",
            dataIndex: "other",
            title: "",
          },
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}
      )
    )
  );
});
