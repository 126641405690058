 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { makeAutoObservable } from "@opendash/state";
import dayjs from "dayjs";
import { OrderTechnicanRelation, } from "../../../parse";
import { $heinzerling } from "../../../service";

export class CalendarState {
   __init() {this.orders = []}

   __init2() {this.start = dayjs().startOf("day").valueOf()}
   __init3() {this.end = dayjs().endOf("day").valueOf()}

   get startDate() {
    return new Date(this.start);
  }

   get endDate() {
    return new Date(this.end);
  }

  constructor() {;CalendarState.prototype.__init.call(this);CalendarState.prototype.__init2.call(this);CalendarState.prototype.__init3.call(this);
    makeAutoObservable(this);

    // this.start = dayjs("2023-04-01").valueOf();
    // this.end = dayjs().endOf("year").valueOf();

    this.fetch();
  }

  async fetch() {
    const technicanOrders = await $heinzerling.sync
      .createQuery(OrderTechnicanRelation)
      .greaterThanOrEqualTo("start", this.startDate)
      .lessThanOrEqualTo("start", this.endDate)
      .equalTo(
        "technican",
        ((await $heinzerling.sync.getUser()) ).get(
          "technican"
        ) 
      )
      .ascending("start")
      .include("order")
      // @ts-ignore
      .include("order.customer")
      //@ts-ignore
      .include("order.facility")
      .find();

    //Filter orders that are not created on mobile and get only the ones that are from sync to get the execution date, not the creation date
    this.setOrders(
      technicanOrders.filter((technicianOrder) => {
        if (!_optionalChain([technicianOrder, 'access', _ => _.get, 'call', _2 => _2("order"), 'optionalAccess', _3 => _3.get, 'call', _4 => _4("local_createdOnMobile")])) {
          return technicianOrder.get("fromSync");
        } else {
          return true;
        }
      })
    );
  }

  setOrders(orders) {
    this.orders = orders.filter((to) => !!_optionalChain([to, 'access', _5 => _5.order, 'optionalAccess', _6 => _6.id]));
  }

  setTimerange(start, end) {
    this.start = start.valueOf();
    this.end = end.valueOf();

    this.fetch();
  }
}
