import Parse from "parse";


















export class OrderFacilityRelation extends Parse.Object {
  static __initStatic() {this.className = "Heinzerling2_OrderFacilityRelation"}

  constructor(data) {
    super("Heinzerling2_OrderFacilityRelation", data );
  }

  get facility() {
    return super.get("facility");
  }
  set facility(value) {
    super.set("facility", value);
  }
  get order() {
    return super.get("order");
  }
  set order(value) {
    super.set("order", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted() {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value) {
    super.set("sqlDeleted", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
} OrderFacilityRelation.__initStatic();

Parse.Object.registerSubclass("Heinzerling2_OrderFacilityRelation", OrderFacilityRelation);
