const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/phone/components/TabSwitch.tsx";import { observer } from "mobx-react-lite";
import React from "react";
import {
  ContactPersonTab,
  NavigationTab,
  OverviewTab,
  UpdateItemsTab,
  UpdatePhotosTab,
  UpdateTechnicansTab,
  UpdateTextsTab,
} from "..";

import HistoryTab from "./HistoryTab";

export default observer(function TabSwitch({ state }) {
  switch (state.tab) {
    case "view-navigation":
      return React.createElement(NavigationTab, { state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}} );
    case "view-contacts":
      return React.createElement(ContactPersonTab, { state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}} );
    case "view-history":
      return React.createElement(HistoryTab, { state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}} );
    case "update-technicans":
      return React.createElement(UpdateTechnicansTab, { state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} );
    case "update-items":
      return React.createElement(UpdateItemsTab, { orderstate: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} );
    case "update-photos":
      return React.createElement(UpdatePhotosTab, { state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} );
    case "update-texts":
      return React.createElement(UpdateTextsTab, { state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} );
    case "view-overview":
    default:
      return React.createElement(OverviewTab, { state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}} );
  }
});
