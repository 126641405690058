
import { Customer, Facility } from "../parse";

export function formatDate(date) {
  if (!date || !date.toLocaleDateString) {
    return "";
  }

  return date.toLocaleDateString("de-DE", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
}

export function formatTime(date) {
  if (!date || !date.toLocaleTimeString) {
    return "";
  }

  return date.toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function formatDateTime(date) {
  if (!date) {
    return "";
  }

  date = new Date(date);

  return formatDate(date) + ", " + formatTime(date);
}

export function formatName(obj) {
  if (!obj) {
    return "-";
  }

  const parts = [obj.get("name"), obj.get("name2"), obj.get("name3")];

  return parts.filter(Boolean).join(", ") || "-";
}

export function formatAddress(
  obj,
  hideType = false
) {
  if (!obj) {
    return null;
  }

  const isFacility = obj instanceof Facility;
  const isCustomer = obj instanceof Customer;

  const suffix = !hideType && isFacility ? " (Aufzug)" : "";

  const parts = [
    obj.get("street"),
    `${obj.get("postcode")} ${obj.get("city")}`,
  ];

  const result = parts.filter(Boolean).join(", ");

  return result + suffix;
}

export function formatAddressWithName(obj) {
  if (!obj) {
    return null;
  }

  const parts = [
    obj.get("name"),
    obj.get("name2"),
    obj.get("name3"),
    obj.get("street"),
    `${obj.get("postcode")} ${obj.get("city")}`,
  ];

  return parts.filter(Boolean).join(", ");
}
