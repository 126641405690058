const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/elevators/components/ElevatorsListRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { AdminLayout, useTranslation } from "@opendash/core";
import { AdminToolbar } from "@opendash/ui";
import { Button, Divider, List, Typography } from "antd";
import Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React, { memo, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Facility } from "../../../parse";

export default memo(() => {
  const t = useTranslation();
  const navigate = useNavigate();
  const { Title } = Typography;

  const [searchString, setSearchString] = useState("");

  const query = useMemo(
    () =>
      new Parse.Query(Facility)
        .ascending("no")
        .exists("customer")
        .limit(1000000)
        .include("customer"),
    []
  );

  const entries = useParseQuery(query);

  const rows = useMemo(() => {
    const search = searchString.toLowerCase();

    return entries.result
      .filter((row) => {
        const facility = row ;
        const customer = facility.get("customer") ;

        if (_optionalChain([facility, 'access', _ => _.get, 'call', _2 => _2("no"), 'optionalAccess', _3 => _3.toString, 'call', _4 => _4(), 'access', _5 => _5.includes, 'call', _6 => _6(search)])) {
          return true;
        }

        if (_optionalChain([customer, 'access', _7 => _7.get, 'call', _8 => _8("name"), 'optionalAccess', _9 => _9.toLowerCase, 'call', _10 => _10(), 'access', _11 => _11.includes, 'call', _12 => _12(search)])) {
          return true;
        }

        const address = [
          facility.get("street"),
          facility.get("postcode"),
          facility.get("city"),
        ].join(" ");

        if (address.includes(search)) {
          return true;
        }

        return false;
      })
      .sort((a, b) => {
        return parseFloat(a.get("no") || "") - parseFloat(b.get("no") || "");
      });
  }, [entries.result, searchString]);

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
      , React.createElement(React.Fragment, null
        , React.createElement(AdminToolbar, {
          title: t("app:admin.elevators.list_title"),
          description: t("app:admin.elevators.list_description"),
          // actions={[
          //   <Button
          //     key="new"
          //     type="primary"
          //     icon={<Icon icon="fa:plus" />}
          //     children={t("app:admin.order.action_new")}
          //     onClick={() => {
          //       createObject("Auftrag").then((objectId) => {
          //         entries.reload();
          //       });
          //     }}
          //   />,
          // ]}
          // search={searchString}
          onSearch: setSearchString, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
        )

        , React.createElement(List, {
          loading: entries.loading,
          dataSource: rows,
          renderItem: (row) => {
            const facility = row ;
            const customer = facility.get("customer") ;

            return (
              React.createElement(List.Item, {
                actions: [
                  React.createElement(Button, {
                    key: "show",
                    type: "primary",
                    children: t("app:admin.elevators.action_show"),
                    onClick: (e) => {
                      e.preventDefault();
                      navigate(`/elevators/${facility.id}`);
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
                  ),
                ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}

                , React.createElement(List.Item.Meta, {
                  title: t("app:admin.elevators.view_title", {
                    name: facility.get("no") || "",
                  }),
                  description: 
                    React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}
                      , customer.get("name"), " - "  , facility.get("street"), " "
                      , facility.get("postcode"), " " , facility.get("city")
                    )
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}
                )
              )
            );
          },
          rowKey: (row) => row.id,
          pagination: {
            defaultCurrent: 1,
            defaultPageSize: 10,
          },
          footer: React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 125}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
        )
      )
      /* <Detector
        offlineChildren={
          <Empty
            style={{ marginTop: 24 }}
            children="Sie sind offline. Die Aufzüge können nicht angezeigt werden."
            description={<Title level={5}>OFFLINE</Title>}
          />
        }
        onlineChildren={
          
        }
      /> */
    )
  );
});
