const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/components/FacilityDescription.tsx";import { useTranslation } from "@opendash/i18n";
import { Descriptions } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { formatAddress, formatDate } from "../helper/formatter";


export default observer(({ facility }) => {
  const t = useTranslation();

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Descriptions, {
        bordered: true,
        size: "small",
        style: { marginBottom: 30 },
        column: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}

        , React.createElement(Descriptions.Item, {
          label: t("app:classes.Facility.fields.no"),
          children: facility.no, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
        )

        , React.createElement(Descriptions.Item, {
          label: t("app:classes.Facility.fields.contractType"),
          children: facility.contractType || "-", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
        )

        , React.createElement(Descriptions.Item, {
          label: t("app:classes.Facility.fields.keyDepot"),
          children: facility.keyDepot || "Keine Angabe", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
        )

        , React.createElement(Descriptions.Item, {
          label: t("app:classes.Facility.fields.address"),
          children: formatAddress(facility, true), __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
        )

        , React.createElement(Descriptions.Item, {
          label: t("app:classes.Facility.fields.lastMaintenance"),
          children: formatDate(facility.lastMaintenance) || "-", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
        )
      )
    )
  );
});
