 function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { message } from "antd";
import { makeAutoObservable, runInAction } from "mobx";
import { Item, } from "../../../parse";
import { $heinzerling } from "../../../service";


export class UpdateItemState {
  
   __init() {this.items = []}
   __init2() {this._open = false}
   __init3() {this._item = null}
   __init4() {this._quantity = null}
   __init5() {this._itemrelation = null}
   __init6() {this._optionsSearch = ""}

  constructor(orderstate) {;UpdateItemState.prototype.__init.call(this);UpdateItemState.prototype.__init2.call(this);UpdateItemState.prototype.__init3.call(this);UpdateItemState.prototype.__init4.call(this);UpdateItemState.prototype.__init5.call(this);UpdateItemState.prototype.__init6.call(this);
    makeAutoObservable(this);
    this.orderstate = orderstate;
  }

   get open() {
    return this._open;
  }

   set open(v) {
    this._open = v;
  }

   get quantity() {
    return this._quantity;
  }

   set quantity(v) {
    this._quantity = v;
  }

   get item() {
    return this._item;
  }

   set item(v) {
    if (typeof v === "string") {
      const item = this.items.find((i) => i.id === v) || null;

      this._item = item;
    } else {
      this._item = v;
    }
  }

   get itemrelation() {
    return this._itemrelation;
  }

   set itemrelation(v) {
    this._itemrelation = v;

    this._item = _optionalChain([v, 'optionalAccess', _ => _.item]) || null;
    this._quantity = _nullishCoalesce(_nullishCoalesce(_optionalChain([v, 'optionalAccess', _2 => _2.local_quantity]), () => ( _optionalChain([v, 'optionalAccess', _3 => _3.quantity]))), () => ( 0));

    this.fetchItems();
  }

  get itemOptions() {
    let items = this.items;

    if (this._optionsSearch === "") {
      items = items.filter((item) => item.inAutofill);
    }

    return this.items.map((item) => ({
      value: item.id,
      label: `[${item.no}] ${item.name}`,
    }));
  }

   get optionsSearch() {
    return this._optionsSearch;
  }

   set optionsSearch(v) {
    this._optionsSearch = v;
  }

   async save() {
    if (!this._itemrelation) {
      return message.error(
        "Es ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu."
      );
    }

    if (this._item === null) {
      return message.error("Es muss ein Artikel ausgewählt werden!");
    }

    if (this._quantity === null) {
      return message.error("Es muss eine Menge eingegeben werden!");
    }

    try {
      this._itemrelation.set("item", this._item);
      this._itemrelation.set("local_quantity", this._quantity);

      await $heinzerling.sync.saveObject(this._itemrelation);

      message.success("Artikel wurde gespeichert.");

      runInAction(() => {
        this._open = false;
        this._itemrelation = null;
        this._item = null;
        this._quantity = null;

        this.orderstate.fetch();
      });
    } catch (error) {
      if (error instanceof Parse.Error) {
        message.error(
          "Speichern fehlgeschlagen: " + error.code + ": " + error.message
        );
      } else {
        message.error("Speichern fehlgeschlagen: Unbekannter Fehler");
      }
    }
    return null;
  }

   async delete() {
    if (this._itemrelation) {
      this._itemrelation.set("local_quantity", 0);
      await $heinzerling.sync.saveObject(this._itemrelation);
    }
    runInAction(() => {
      this._open = false;
      this._itemrelation = null;
      this._item = null;
      this._quantity = null;

      this.orderstate.fetch();
    });
  }

   async fetchItems() {
    if (this.items.length > 0) return;

    const items = await $heinzerling.sync
      .createQuery(Item)
      .limit(1000000)
      .find();

    runInAction(() => {
      this.items = items;
    });
  }
}
