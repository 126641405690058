const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/signature/components/SignatureCanvas.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { $framework } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";


export default observer(({ value, onChange }) => {
  const canvas = useRef(null);
  const cropCanvas = useRef(null);
  let canvasbox;
  let ctx;
  let cropctx;
  let flag = false;
  let prevX = 0;
  let currX = 0;
  let prevY = 0;
  let currY = 0;
  let dot_flag = false;

  function change() {
    if (canvas.current === null || typeof canvas.current === "undefined") {
      return;
    }
    let dataURL = cropImageFromCanvas();
    if (typeof onChange === "function" && dataURL) {
      onChange(dataURL);
    }
  }

  function draw() {
    // ctx = canvas.current?.getContext("2d")!;
    ctx.beginPath();
    ctx.moveTo(prevX, prevY);
    ctx.lineTo(currX, currY);
    ctx.strokeStyle = "black";
    ctx.lineWidth = 2;
    ctx.stroke();
    ctx.closePath();
    change();
  }

  function erase() {
    ctx = _optionalChain([canvas, 'access', _ => _.current, 'optionalAccess', _2 => _2.getContext, 'call', _3 => _3("2d")]);
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    cropctx = _optionalChain([cropCanvas, 'access', _4 => _4.current, 'optionalAccess', _5 => _5.getContext, 'call', _6 => _6("2d")]);
    cropctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    change();
  }

  function getMousePosition(res, e) {
    ctx = _optionalChain([canvas, 'access', _7 => _7.current, 'optionalAccess', _8 => _8.getContext, 'call', _9 => _9("2d")]);
    if (res == "down") {
      prevX = currX;
      prevY = currY;
      currX = e.clientX - (_optionalChain([canvasbox, 'optionalAccess', _10 => _10.left]) ? _optionalChain([canvasbox, 'optionalAccess', _11 => _11.left]) : 0);
      currY = e.clientY - (_optionalChain([canvasbox, 'optionalAccess', _12 => _12.top]) ? _optionalChain([canvasbox, 'optionalAccess', _13 => _13.top]) : 0);

      flag = true;
      dot_flag = true;
      if (dot_flag) {
        ctx.beginPath();
        ctx.fillStyle = "black";
        ctx.fillRect(currX, currY, 2, 2);
        ctx.closePath();
        dot_flag = false;
      }
    }
    if (res == "up" || res == "out") {
      flag = false;
    }
    if (res == "move") {
      if (flag) {
        prevX = currX;
        prevY = currY;
        currX = e.clientX - (_optionalChain([canvasbox, 'optionalAccess', _14 => _14.left]) ? _optionalChain([canvasbox, 'optionalAccess', _15 => _15.left]) : 0);
        currY = e.clientY - (_optionalChain([canvasbox, 'optionalAccess', _16 => _16.top]) ? _optionalChain([canvasbox, 'optionalAccess', _17 => _17.top]) : 0);
        draw();
      }
    }
  }

  function drawOrigin() {
    // Draw origin
    ctx.fillStyle = "red";
    ctx.fillRect(0, 0, 5, 5);
    // Draw border
    ctx.lineWidth = 2;
    ctx.strokeStyle = "blue";
    ctx.strokeRect(0, 0, canvasbox.width, canvasbox.height);
  }

  function cropImageFromCanvas() {
    let w = canvas.current.width;
    let h = canvas.current.height;
    let pix = { x: [] , y: []  };
    let imageData = ctx.getImageData(
      0,
      0,
      canvas.current.width,
      canvas.current.height
    );
    let x;
    let y;
    let index;

    for (y = 0; y < h; y++) {
      for (x = 0; x < w; x++) {
        index = (y * w + x) * 4;
        if (imageData.data[index + 3] > 0) {
          pix.x.push(x);
          pix.y.push(y);
        }
      }
    }
    pix.x.sort(function (a, b) {
      return a - b;
    });
    pix.y.sort(function (a, b) {
      return a - b;
    });
    let n = pix.x.length - 1;

    w = 1 + pix.x[n] - pix.x[0];
    h = 1 + pix.y[n] - pix.y[0];
    //Check if w and h are not NaN and pix.x and pix.y are not empty
    if (isNaN(w) || isNaN(h) || pix.x.length === 0 || pix.y.length === 0) {
      return;
    }
    let cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

    cropctx.canvas.width = w;
    cropctx.canvas.height = h;
    cropctx.putImageData(cut, 0, 0);

    return cropctx.canvas.toDataURL();
  }

  useEffect(() => {
    if (
      !canvas.current ||
      !canvas.current.getContext("2d") ||
      !canvas.current.getBoundingClientRect()
    ) {
      return;
    }

    canvasbox = _optionalChain([canvas, 'access', _18 => _18.current, 'optionalAccess', _19 => _19.getBoundingClientRect, 'call', _20 => _20()]);
    ctx = _optionalChain([canvas, 'access', _21 => _21.current, 'optionalAccess', _22 => _22.getContext, 'call', _23 => _23("2d")]);
    ctx.canvas.height = canvasbox.bottom - canvasbox.top;
    ctx.canvas.width = canvasbox.right - canvasbox.left;

    cropctx = _optionalChain([cropCanvas, 'access', _24 => _24.current, 'optionalAccess', _25 => _25.getContext, 'call', _26 => _26("2d")]);
    cropctx.canvas.height = canvasbox.bottom - canvasbox.top;
    cropctx.canvas.width = canvasbox.right - canvasbox.left;

    // Set an image if one is provided
    if (value) {
      let image = new Image();
      image.onload = function () {
        ctx.drawImage(image, 0, 0);
      };
      image.src = value;
    }

    screen.orientation.addEventListener("change", () => {
      //Delete the canvas content when rotated
      erase();

      // Timeout is needed so that elements are updated
      setTimeout(() => {
        canvasbox = _optionalChain([canvas, 'access', _27 => _27.current, 'optionalAccess', _28 => _28.getBoundingClientRect, 'call', _29 => _29()]);

        ctx.canvas.height = canvasbox.height;
        ctx.canvas.width = canvasbox.width;
        canvas.current.height = canvasbox.height;
        canvas.current.width = canvasbox.width;

        cropctx.canvas.height = canvasbox.height;
        cropctx.canvas.width = canvasbox.width;
        cropCanvas.current.height = canvasbox.height;
        cropCanvas.current.width = canvasbox.width;

        $framework.antd_msg.info("Aktuelle Unterschrift gelöscht");
      }, 500);
    });

    canvas.current.addEventListener(
      "mousemove",
      function (e) {
        getMousePosition("move", e);
      },
      false
    );
    canvas.current.addEventListener(
      "mousedown",
      function (e) {
        getMousePosition("down", e);
      },
      false
    );
    canvas.current.addEventListener(
      "mouseup",
      function (e) {
        getMousePosition("up", e);
      },
      false
    );
    canvas.current.addEventListener(
      "mouseout",
      function (e) {
        getMousePosition("out", e);
      },
      false
    );
    canvas.current.addEventListener(
      "touchstart",
      function (e) {
        e.preventDefault();
        e.stopPropagation();
        var touch = e.touches[0];
        var mouseEvent = new MouseEvent("mousedown", {
          clientX: touch.clientX,
          clientY: touch.clientY,
        });
        getMousePosition("down", mouseEvent);
      },
      false
    );
    canvas.current.addEventListener(
      "touchend",
      function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.touches.length > 0) {
          var touch = e.touches[0];
          var mouseEvent = new MouseEvent("mouseup", {
            clientX: touch.clientX,
            clientY: touch.clientY,
          });
          getMousePosition("up", mouseEvent);
        }
      },
      false
    );
    canvas.current.addEventListener(
      "touchmove",
      function (e) {
        e.preventDefault();
        e.stopPropagation();
        var touch = e.touches[0];
        var mouseEvent = new MouseEvent("mousemove", {
          clientX: touch.clientX,
          clientY: touch.clientY,
        });
        getMousePosition("move", mouseEvent);
      },
      false
    );
  }, []);

  return (
    React.createElement('div', {
      style: {
        position: "relative",
        border: "1px solid grey",
        height: "100%",
        width: "100%",
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 265}}

      , React.createElement('canvas', {
        ref: canvas,
        style: {
          height: "100%",
          width: "100%",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 273}}
      )
      , React.createElement('canvas', {
        ref: cropCanvas,
        style: {
          height: "100%",
          width: "100%",
          display: "none",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 280}}
      )

      , React.createElement(Button, {
        danger: true,
        type: "primary",
        size: "large",
        shape: "circle",
        onClick: () => {
          erase();
        },
        style: {
          position: "absolute",
          bottom: 10,
          right: 10,
        },
        icon: React.createElement(Icon, { icon: "fa:eraser", __self: this, __source: {fileName: _jsxFileName, lineNumber: 302}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 289}}
      )
    )
  );
});
