const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/items/components/UpdateItemDrawer.tsx"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Button, Drawer, Form, InputNumber, Select, Space } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";


export default observer(({ state }) => {
  return (
    React.createElement(Drawer, {
      title: "Artikel bearbeiten" ,
      placement: "bottom",
      open: !!state.open,
      onClose: () => (state.open = false), __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}

      , React.createElement(Form, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
        , React.createElement(Form.Item, {
          label: "Artikel",
          validateStatus: state.item === null ? "error" : "success", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}

          , React.createElement(Select, {
            showSearch: true,
            optionFilterProp: "children",
            placeholder: "Wähle einen Artikel aus"   ,
            options: state.itemOptions,
            value: _optionalChain([state, 'access', _ => _.item, 'optionalAccess', _2 => _2.id]),
            onChange: (value) => {
              state.item = value;
            },
            onSearch: (searchString) => {
              state.optionsSearch = searchString || "";
            },
            filterOption: (input, option) =>
              (_nullishCoalesce(_optionalChain([option, 'optionalAccess', _3 => _3.label]), () => ( ""))).toLowerCase().includes(input.toLowerCase())
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
          )
        )
        , React.createElement(Form.Item, {
          label: "Menge",
          validateStatus: state.quantity === null ? "error" : "success", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}

          , React.createElement(InputNumber, {
            value: state.quantity,
            decimalSeparator: ",",
            onChange: (value) => {
              state.quantity = value;
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
          )
        )
        , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
          , React.createElement(Space, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
            , React.createElement(Button, {
              type: "primary",
              children: "Speichern",
              onClick: () => {
                state.save();
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
            )
            , React.createElement(Button, {
              danger: true,
              type: "primary",
              children: "Löschen",
              onClick: () => {
                state.delete();
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
            )
          )
        )
      )
    )
  );
});
