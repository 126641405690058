const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/pages/calendar/PhoneCalendar.tsx";import { AdminLayout } from "@opendash/core";
import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { OrdersList } from "../../features/orders";
import { CalendarState, WeeklyHeader } from "../../features/phone";

export const PhoneCalendarPage = observer(() => {
  const state = useMemo(() => new CalendarState(), []);

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
      , React.createElement(WeeklyHeader, {
        start: state.startDate,
        end: state.endDate,
        onChange: (start, end) => state.setTimerange(start, end), __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
      )

      , React.createElement(Divider, { children: "Aufträge", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} )

      , React.createElement(OrdersList, {
        rows: state.orders,
        loading: false,
        hideElevatorLink: true,
        linkToMobile: true,
        allowSelection: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
      )
    )
  );
});
