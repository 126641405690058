import Parse from "parse";





























export class OrderItemRelation extends Parse.Object {
  static __initStatic() {this.className = "Heinzerling2_OrderItemRelation"}

  constructor(data) {
    super("Heinzerling2_OrderItemRelation", data );
  }

  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get description2() {
    return super.get("description2");
  }
  set description2(value) {
    super.set("description2", value);
  }
  get description3() {
    return super.get("description3");
  }
  set description3(value) {
    super.set("description3", value);
  }
  get description4() {
    return super.get("description4");
  }
  set description4(value) {
    super.set("description4", value);
  }
  get fromSync() {
    return super.get("fromSync");
  }
  set fromSync(value) {
    super.set("fromSync", value);
  }
  get item() {
    return super.get("item");
  }
  set item(value) {
    super.set("item", value);
  }
  get local_deleted() {
    return super.get("local_deleted");
  }
  set local_deleted(value) {
    super.set("local_deleted", value);
  }
  get local_quantity() {
    return super.get("local_quantity");
  }
  set local_quantity(value) {
    super.set("local_quantity", value);
  }
  get order() {
    return super.get("order");
  }
  set order(value) {
    super.set("order", value);
  }
  get parentPosition() {
    return super.get("parentPosition");
  }
  set parentPosition(value) {
    super.set("parentPosition", value);
  }
  get position() {
    return super.get("position");
  }
  set position(value) {
    super.set("position", value);
  }
  get quantity() {
    return super.get("quantity");
  }
  set quantity(value) {
    super.set("quantity", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted() {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value) {
    super.set("sqlDeleted", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
  get sys_app_id() {
    return super.get("sys_app_id");
  }
  set sys_app_id(value) {
    super.set("sys_app_id", value);
  }
} OrderItemRelation.__initStatic();

Parse.Object.registerSubclass("Heinzerling2_OrderItemRelation", OrderItemRelation);
