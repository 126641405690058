import { message } from "antd";
import { makeAutoObservable } from "mobx";

import Parse from "parse";

export class HeinzerlingHelperService {
  constructor(app) {
    makeAutoObservable(this);
  }

  handleError(type, error) {
    const prefix =
      type === "save" ? "Speichern fehlgeschlagen" : "Abfrage fehlgeschlagen";

    if (error instanceof Parse.Error) {
      message.error(`${prefix}: [${error.code}] ${error.message}`);
    } else {
      message.error(`${prefix}: Unbekannter Fehler`);
    }
  }
}
