 function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { message } from "antd";
import { makeAutoObservable } from "mobx";
import { UpdateItemState } from "..";



export class PartsListState {
  
  
   __init() {this._open = false}
   __init2() {this._name = ""}
   __init3() {this._disabled = false}
   __init4() {this._orderitemrelation = null}

  constructor(oir, state) {;PartsListState.prototype.__init.call(this);PartsListState.prototype.__init2.call(this);PartsListState.prototype.__init3.call(this);PartsListState.prototype.__init4.call(this);
    this._orderstate = state;
    this._orderitemrelation = oir;
    this._updateitemstate = new UpdateItemState(this.orderstate);
    makeAutoObservable(this);
  }

   get open() {
    return this._open;
  }

   set open(v) {
    this._open = v;
  }

   get name() {
    return this._name;
  }

   set name(v) {
    this._name = v;
  }

   get disabled() {
    return this._disabled;
  }

   set disabled(v) {
    this._disabled = v;
  }

   get orderstate() {
    return this._orderstate;
  }

   get updateitemstate() {
    return this._updateitemstate;
  }

   get parts() {
    const parent = this._orderitemrelation;

    if (!parent) {
      return [];
    }

    return this._orderstate.items.filter(
      (i) =>
        i.parentPosition === parent.position &&
        !i.local_deleted &&
        !_optionalChain([i, 'access', _ => _.get, 'call', _2 => _2("item"), 'optionalAccess', _3 => _3.get, 'call', _4 => _4("resource")])
    );
  }

   getPartQuantity(ir) {
    return (_nullishCoalesce(ir.local_quantity, () => ( ir.quantity))) + " " + _optionalChain([ir, 'access', _5 => _5.item, 'optionalAccess', _6 => _6.unit]);
  }

   getPartTitle(ir) {
    let title = "";
    if (typeof ir.item === "undefined") {
      title = ir.description;
    }

    if (typeof ir.item !== "undefined" && title.length === 0) {
      title = ir.item.name || ir.item.description4;
    }

    // If this is a dummy then display the description from the order
    if (title.toLowerCase() === "dummy") {
      title = ir.description;
    }

    return title;
  }

   showPartsList(orderitemrelation) {
    return (
      this._orderstate.items.filter(
        (ir) => orderitemrelation.position === ir.parentPosition
      ).length > 0
    );
  }

   copyPartsListToClipboard() {
    const copyText = this.parts
      .map((part) => this.getPartQuantity(part) + " " + this.getPartTitle(part))
      .join("\r\n");

    navigator.clipboard.writeText(copyText).then(
      () => {
        message.success("Kopiert", 5);
      },
      () => {
        message.error("Fehler beim Kopieren", 5);
      }
    );
  }

   copyPartToClipboard(part) {
    const copyText = this.getPartQuantity(part) + " " + this.getPartTitle(part);

    navigator.clipboard.writeText(copyText).then(
      () => {
        message.success("Kopiert", 5);
      },
      () => {
        message.error("Fehler beim Kopieren", 5);
      }
    );
  }

  // No functionality currently because it is not supported by orderbase
   addItem() {}

   setUpdateDialog(value) {
    this._updateitemstate.itemrelation = value;
    this._updateitemstate.open = true;
  }
}
