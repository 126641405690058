const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/pages/orders/PhoneOrderPage.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { AdminLayout } from "@opendash/core";
import { Tabs } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { OrderState } from "../../features/orders";
import { TabContent, TabLabel } from "../../features/phone";
import { useQuery } from "../../hooks/useQuery";

export const PhoneOrderPage = observer(() => {
  const query = useQuery();
  const { id, tab } = useParams();
  const navigate = useNavigate();

  const state = useMemo(
    () => new OrderState(id, query.get("rel") ? query.get("rel") : undefined),
    [id]
  );

  useEffect(() => {
    if (!tab) {
      navigate(_optionalChain([state, 'access', _ => _.menu, 'access', _2 => _2[0], 'optionalAccess', _3 => _3.url]), { replace: true });
    } else {
      state.setTab(tab);
    }
  }, [tab]);

  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
      , React.createElement(Tabs, {
        style: { height: "100%" },
        centered: true,
        animated: true,
        tabPosition: "bottom",
        activeKey: tab,
        items: state.menu.map((menuitem) => {
          return {
            key: menuitem.key,
            label: React.createElement(TabLabel, { menuitem: menuitem, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}} ),
            children: React.createElement(TabContent, { state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}} ),
          };
        }),
        onTabClick: (key) => {
          const m = state.menu.find((m) => m.key === key);

          if (m) {
            navigate(m.url, { replace: true });
          }
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
      )
    )
  );
});
