const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/components/DisplayText.tsx";import React, { memo } from "react";






export const DisplayText = memo(function DisplayText({
  text,
  fallback,
}) {
  if (!text) {
    if (fallback) {
      return React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}, fallback);
    } else {
      return null;
    }
  }

  text = text
    .split("\n\n")
    .map((t) => `<p>${t}</p>`)
    .join("");

  text = text.replaceAll("\n", "<br/>");

  return (
    React.createElement('div', {
      dangerouslySetInnerHTML: {
        __html: text,
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
    )
  );
});
