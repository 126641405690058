const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/phone/components/TabContent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Icon } from "@opendash/icons";
import { Button, Spin } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { TabSwitch } from "..";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { $heinzerling } from "../../../service";
import { PageActions } from "../../orders";

const TabContentContainer = styled.div`
  // border-left: 5px solid red;

  padding: 16px;

  overflow: auto;
  height: calc(100vh - var(--opendash-header-height) - 64px - 40px);
  width: 100%;

  .ant-spin {
    width: 100%;
    text-align: center;
    padding: 50px 0;
  }

  .ant-page-header {
    padding: 0 0 16px 0;
  }
`;

export default observer(function TabContent({ state }) {
  const navigate = useNavigate();

  if (state.loading) {
    return (
      React.createElement(TabContentContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
        , React.createElement(Spin, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 38}} )
      )
    );
  }

  if (state.notFound) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Auftrag nicht gefunden"  ,
        message: "Unter dieser URL konnte kein Auftrag gefunden werden."       , __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
      )
    );
  }

  return (
    React.createElement(TabContentContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
      , React.createElement(PageActions, {
        title: _optionalChain([state, 'access', _ => _.currentMenu, 'optionalAccess', _2 => _2.label]),
        subTitle: state.title,
        onBack: () => {
          navigate(
            state.backLink,
            typeof state.backLink === "string" ? { replace: true } : undefined
          );
        },
        extra: 
          React.createElement(Button, {
            type: "primary",
            size: "large",
            shape: "circle",
            disabled: state.disabled,
            onClick: () => {
              $heinzerling.mobile.signOrders([state.id]);
            },
            icon: React.createElement(Icon, { icon: "fa:signature", __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
          )
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
      )
      , React.createElement(TabSwitch, { state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}} )
    )
  );
});
