const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/elevators/components/ElevatorsViewRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { AdminLayout, useTranslation } from "@opendash/core";
import { ParseObject } from "@opendash/plugin-parse";
import { Divider } from "antd";
import Parse from "parse";
import { useParseGet, useParseQuery } from "parse-hooks";
import React, { memo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { Facility, Order } from "../../../parse";
import { OrdersList } from "../../orders";

export default memo(function ElevatorsViewRoute() {
  const t = useTranslation();
  const navigate = useNavigate();

  const { elevatorId } = useParams();

  const elevator = useParseGet(Facility, elevatorId , {
    includes: ["customer"],
  });

  const orderQuery = React.useMemo(
    () =>
      new Parse.Query(Order)
        .equalTo("facility", elevator.result)
        .exists("date")
        .exists("customer")
        .descending("date")
        .include("customer")
        .include("facility")
        .limit(1000000),
    [_optionalChain([elevator, 'access', _ => _.result, 'optionalAccess', _2 => _2.id])]
  );

  const orders = useParseQuery(orderQuery);

  if (!elevator.result || elevator.error) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Aufzug nicht gefunden"  ,
        message: "Unter dieser URL konnte kein Aufzug gefunden werden."       , __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
      )
    );
  }

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
      , React.createElement(ParseObject, {
        object: elevator.result,
        fields: ["no", "street", "postcode", "city"],
        editFields: ["no", "street", "postcode", "city"], __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
      )
      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 54}} )

      , React.createElement(OrdersList, {
        rows: orders.result,
        loading: orders.loading,
        hideElevatorLink: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
      )
    )
  );
});
