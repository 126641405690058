const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/pages/Phone/Offline.tsx";import { AdminLayout } from "@opendash/core";
import { Result, Typography } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { useQuery } from "../../hooks/useQuery";

const { Title, Paragraph, Text, Link } = Typography;

export const OfflineNotice = observer(() => {
  const query = useQuery();
  const action = query.get("action");

  function getMessage() {
    if (!action) {
      return (
        React.createElement(Result, {
          status: "warning",
          title: "Entschuldigung, es ist ein Problem aufgetreten"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
        )
      );
    }

    if (action === "order_created") {
      return (
        React.createElement(Result, {
          title: "Der Auftrag wurde angelegt"   ,
          extra: 
            React.createElement(Paragraph, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}, "Sie sind aktuell offline. Der Auftrag wurde erstellt und wird synchronisiert, sobald Sie online sind. Danach können Sie ihn aufrufen."



            )
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
        )
      );
    }
    return React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 37}});
  }

  return React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}, getMessage());
});
