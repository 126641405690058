import Parse from "parse";


























export class Facility extends Parse.Object {
  static __initStatic() {this.className = "Heinzerling2_Facility"}

  constructor(data) {
    super("Heinzerling2_Facility", data );
  }

  get city() {
    return super.get("city");
  }
  set city(value) {
    super.set("city", value);
  }
  get contractType() {
    return super.get("contractType");
  }
  set contractType(value) {
    super.set("contractType", value);
  }
  get customer() {
    return super.get("customer");
  }
  set customer(value) {
    super.set("customer", value);
  }
  get keyDepot() {
    return super.get("keyDepot");
  }
  set keyDepot(value) {
    super.set("keyDepot", value);
  }
  get lastMaintenance() {
    return super.get("lastMaintenance");
  }
  set lastMaintenance(value) {
    super.set("lastMaintenance", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get name2() {
    return super.get("name2");
  }
  set name2(value) {
    super.set("name2", value);
  }
  get name3() {
    return super.get("name3");
  }
  set name3(value) {
    super.set("name3", value);
  }
  get no() {
    return super.get("no");
  }
  set no(value) {
    super.set("no", value);
  }
  get postcode() {
    return super.get("postcode");
  }
  set postcode(value) {
    super.set("postcode", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted() {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value) {
    super.set("sqlDeleted", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
  get street() {
    return super.get("street");
  }
  set street(value) {
    super.set("street", value);
  }
} Facility.__initStatic();

Parse.Object.registerSubclass("Heinzerling2_Facility", Facility);
