const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/orders/components/OrderTile.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Icon } from "@opendash/icons";
import { Button, Card, Descriptions, Tag } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router";
import { formatDateTime } from "../../../helper/formatter";

import { $heinzerling } from "../../../service";





export default observer(({ ot }) => {
  const navigate = useNavigate();

  const readOnly = $heinzerling.mobile.isOrderReadOnly(ot.order);

  const customer = _optionalChain([ot, 'access', _ => _.order, 'optionalAccess', _2 => _2.customer]);

  const title = _optionalChain([ot, 'access', _3 => _3.order, 'optionalAccess', _4 => _4.no]) + " " + _optionalChain([customer, 'optionalAccess', _5 => _5.name]);
  const metaTitle = undefined;
  const metaDescription = formatDateTime(ot.start);
  const type = _optionalChain([ot, 'access', _6 => _6.get, 'call', _7 => _7("order"), 'optionalAccess', _8 => _8.get, 'call', _9 => _9("type")]) || "Unbekannt";

  const details = {
    Auftragsnummer: _optionalChain([ot, 'access', _10 => _10.order, 'optionalAccess', _11 => _11.no]) || "-",
    Ansprechpartner: _optionalChain([ot, 'access', _12 => _12.order, 'optionalAccess', _13 => _13.referenceContact]) || "-",
  };

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Card, {
        title: title,
        style: { marginBottom: 20 },
        extra: getTypeTag(type),
        actions: [
          React.createElement(Button, {
            type: "text",
            onClick: () => {
              navigate(`/phone/order/${ot.get("order").id}`);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}

            , React.createElement(Icon, { icon: "fa:eye", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}} )
          ),
          React.createElement(Button, {
            disabled: readOnly,
            type: "text",
            onClick: () => {
              $heinzerling.mobile.signOrders([ot.get("order").id]);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}

            , React.createElement(Icon, { icon: "fa:signature", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} )
          ),
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}

        , React.createElement(Card.Meta, {
          title: metaTitle,
          description: metaDescription,
          style: { marginBottom: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
        )

        , React.createElement(Descriptions, { size: "small", column: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
          , Object.entries(details).map(([label, children], i) => (
            React.createElement(Descriptions.Item, { key: i, label: label, children: children, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}} )
          ))
        )
      )
    )
  );
});

function getTypeTag(type) {
  switch (type) {
    case "STÖRUNG":
      return React.createElement(Tag, { color: "error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}, type);

    case "WART":
      return React.createElement(Tag, { color: "warning", __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}, type);

    case "TÜV":
      return React.createElement(Tag, { color: "processing", __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}, type);

    case "SERVICE":
      return React.createElement(Tag, { color: "success", __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}, type);

    default:
      return React.createElement(Tag, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}, type);
  }
}
