const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/pages/orders/OrderDetailPage.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { $framework, AdminLayout, useTranslation } from "@opendash/core";
import { ParseObject } from "@opendash/plugin-parse";
import { AdminToolbar } from "@opendash/ui";
import { Button, Divider } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorMessage } from "../../components/ErrorMessage";
import { OrderMedia, PrintView } from "../../features/orders";
import { Order } from "../../parse";
import { $heinzerling } from "../../service";

export const OrderDetailPage = observer(() => {
  const t = useTranslation();
  const { orderId } = useParams();
  const [order, setOrder] = useState();

  useEffect(() => {
    async function getOrder() {
      return await $heinzerling.sync
        .createQuery(Order)
        .include("customer")
        .include("facility")
        .get(orderId );
    }
    getOrder().then(setOrder);
  }, []);

  if (!order) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Facility nicht gefunden"  ,
        message: "Unter dieser URL konnte kein Facility gefunden werden."       , __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
      )
    );
  }

  const pdf = _optionalChain([order, 'access', _ => _.get, 'call', _2 => _2("sys_pdf"), 'optionalAccess', _3 => _3.url, 'call', _4 => _4()]);
  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      , React.createElement(AdminToolbar, {
        title: t("app:string.title_auftrag") + " " + order.get("no"),
        actions: [
          React.createElement(Button, {
            key: "toorder",
            children: t("app:orders.action_goto_app"),
            onClick: () =>
              $framework.router.navigate(
                `/phone/order/${orderId}/view-overview`
              )
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
          ),
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
      )
      , React.createElement(ParseObject, {
        object: order,
        fields: [
          "no",
          "date",
          "type",
          "local_documentText",
          "local_finishedText",
        ],
        editFields: ["local_documentText", "local_finishedText"],
        disableDelete: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
      )

      , React.createElement(OrderMedia, { order: order, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}} )

      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 71}} )

      , pdf && (
        React.createElement(PrintView, {
          title: t("app:orders.workreport.title"),
          preview: pdf,
          download: pdf, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
        )
      )

      , !pdf && (
        React.createElement(PrintView, {
          title: t("app:orders.workreport.title_preview"),
          preview: `https://heinzerling-print-service-dev.apps.openinc.dev/render/auftragsbestaetigung/${orderId}`,
          download: `https://heinzerling-print-service-dev.apps.openinc.dev/print/auftragsbestaetigung/${orderId}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
        )
      )
    )
  );
});
