import { makeAutoObservable, runInAction } from "mobx";
import { calculatePosition } from "..";
import { Item, OrderItemRelation } from "../../../parse";
import { $heinzerling } from "../../../service";


export class AddItemState {
  
   __init() {this.items = []}
   __init2() {this._open = false}
   __init3() {this._partsList = null}
  // private _itemrelation: OrderItemRelation | null = null;
   __init4() {this._item = null}
   __init5() {this._quantity = null}
   __init6() {this._optionsSearch = ""}

  constructor(orderstate) {;AddItemState.prototype.__init.call(this);AddItemState.prototype.__init2.call(this);AddItemState.prototype.__init3.call(this);AddItemState.prototype.__init4.call(this);AddItemState.prototype.__init5.call(this);AddItemState.prototype.__init6.call(this);
    this.orderstate = orderstate;
    this.fetchItems();
    makeAutoObservable(this);
  }

   get open() {
    return this._open;
  }

   set open(v) {
    this._open = v;
  }

   get partsList() {
    return this._partsList;
  }

   set partsList(v) {
    this._partsList = v;
  }

   get item() {
    return this._item;
  }

   set item(v) {
    if (typeof v === "string") {
      const item = this.items.find((i) => i.id === v) || null;

      this._item = item;
    } else {
      this._item = v;
    }
  }

  get itemOptions() {
    let items = this.items;

    if (this._optionsSearch === "") {
      items = items.filter((item) => item.inAutofill);
    }

    return this.items.map((item) => ({
      value: item.id,
      label: `[${item.no}] ${item.name}`,
    }));
  }

   get optionsSearch() {
    return this._optionsSearch;
  }

   set optionsSearch(v) {
    this._optionsSearch = v;
  }

   get quantity() {
    return this._quantity;
  }

   set quantity(v) {
    this._quantity = v;
  }

  // public get itemrelation(): AddItemState["_itemrelation"] {
  //   return this._itemrelation;
  // }

  // public set itemrelation(v: AddItemState["_itemrelation"]) {
  //   this._itemrelation = v;

  //   this._item = v?.item || null;
  //   this._quantity = v?.local_quantity ?? v?.quantity ?? 0;

  //   this.fetchItems();
  // }

   async save() {
    const parent = this._partsList;

    const position = calculatePosition(parent, this.orderstate);

    const parentPosition = parent ? parent.position : undefined;

    if (this._item) {
      const relation = new OrderItemRelation({
        order: this.orderstate.order,
        local_quantity: this._quantity ? this._quantity : 0,
        position,
        parentPosition,
        item: this._item,
      });

      await runInAction(async () => {
        await $heinzerling.sync.saveObject(relation);
        this.orderstate.items.push(relation);
      });

      this.resetDrawer();
    }
  }

   cancel() {
    this.resetDrawer();
  }

   resetDrawer() {
    this._item = null;
    this._quantity = null;
    this._optionsSearch = "";
    this._partsList = null;
    this._open = false;
  }

   async fetchItems() {
    if (this.items.length > 0) return;

    const items = await $heinzerling.sync
      .createQuery(Item)
      .limit(1000000)
      .find();

    runInAction(() => {
      this.items = items;
    });
  }
}
