 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { message } from "antd";
import dayjs from "dayjs";
import { makeAutoObservable, runInAction } from "mobx";
import { ContactPerson, Order, } from "../../../parse";
import { $heinzerling } from "../../../service";







export class SignatureDrawerState {
   __init() {this.loading = true}
   __init2() {this.sending = false}

  
   __init3() {this.orders = []}

   __init4() {this.customerSignature = undefined}
   __init5() {this.technicanSignature = undefined}

   __init6() {this.emails = []}

   __init7() {this.stepIndex = 0}
   __init8() {this.steps = [
    {
      key: "overview",
      label: "Auftrag abschließen",
    },
    {
      key: "customer",
      label: "Unterschrift Kunde",
    },
    {
      key: "technican",
      label: "Unterschrift Monteur",
    },
    {
      key: "finish",
      label: "Auftrag abschließen",
    },
  ]}

   __init9() {this.emailregex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/}

  constructor(ids) {;SignatureDrawerState.prototype.__init.call(this);SignatureDrawerState.prototype.__init2.call(this);SignatureDrawerState.prototype.__init3.call(this);SignatureDrawerState.prototype.__init4.call(this);SignatureDrawerState.prototype.__init5.call(this);SignatureDrawerState.prototype.__init6.call(this);SignatureDrawerState.prototype.__init7.call(this);SignatureDrawerState.prototype.__init8.call(this);SignatureDrawerState.prototype.__init9.call(this);
    this.ids = ids;

    makeAutoObservable(this);

    this.fetch();
  }

  get isLastStep() {
    return this.stepIndex === this.steps.length - 1;
  }

  get step() {
    return this.steps[this.stepIndex];
  }

  get disabled() {
    if (this.step.key === "customer") {
      return !this.customerSignature;
    }

    if (this.step.key === "technican") {
      return !this.technicanSignature;
    }

    return false;
  }

  next() {
    this.stepIndex = Math.min(
      this.steps.length - 1,
      Math.max(0, this.stepIndex + 1)
    );
  }

  setCustomerSignature(value) {
    this.customerSignature = value;
  }

  setTechnicanSignature(value) {
    this.technicanSignature = value;
  }

  async fetch() {
    runInAction(() => {
      this.loading = true;
    });

    try {
      const orders = await Promise.all(
        this.ids.map((id) =>
          $heinzerling.sync
            .createQuery(Order)
            .include("customer")
            .include("facility")
            .get(id)
        )
      );

      let emails = [];
      const user = await $heinzerling.sync.getUser();
      if (user && user.getEmail()) {
        emails = [
          {
            value: user.getEmail(),
            label: user.getEmail(),
            key: user.getEmail(),
          },
        ];

        //Add email for specific user
        //TODO: This should be deleted on prod
        if (_optionalChain([user, 'optionalAccess', _ => _.getEmail, 'call', _2 => _2()]) === "j.wiederstein@heinzerling-aufzuege.de") {
          emails.push({
            value: "arbeitsberichte@heinzerling-aufzuege.de",
            label: "arbeitsberichte@heinzerling-aufzuege.de",
            key: "arbeitsberichte@heinzerling-aufzuege.de",
          });
        }
      }

      (
        await Promise.all(
          orders.map((order) =>
            $heinzerling.sync
              .createQuery(ContactPerson)
              .equalTo("customer", order.get("customer"))
              .find()
          )
        )
      )
        .flat()
        .map((contactperson) => {
          const cpemail = contactperson.get("email");
          if (cpemail) {
            emails.push({
              key: cpemail,
              value: cpemail,
              label: cpemail,
            });
          }
        });

      runInAction(() => {
        this.orders = orders;
        this.emails = emails;
        this.loading = false;
      });
    } catch (error) {
      $heinzerling.helper.handleError("query", error);

      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async save(data) {
    this.sending = true;

    const hideMessage = message.loading("Auftrag wird abgeschlossen", 0);

    for (const order of this.orders) {
      order.set("local_signatureTimestamp", new Date());
      order.set("local_signatureCustomer", this.customerSignature);
      order.set("local_signatureTechnican", this.technicanSignature);

      for (let [key, value] of Object.entries(data)) {
        if (dayjs.isDayjs(value)) {
          value = value.toDate();
        }

        if (key === "local_mailto" && typeof value !== "undefined") {
          value = (value ).join(",");
        }
        order.set(key , value);
      }

      await $heinzerling.sync.saveObject(order);
    }

    $heinzerling.mobile.signOrders(null);
    hideMessage();
    message.success("Auftrag abgespeichert.");
  }
}
