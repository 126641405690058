const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/items/components/CreateItemDrawer.tsx";import { Button, Drawer, Form, Input, Space } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";


export default observer(({ state }) => {
  return (
    React.createElement(Drawer, {
      title: "Neuer Artikel" ,
      placement: "bottom",
      open: !!state.open,
      onClose: () => (state.open = false),
      height: "571px", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}

      , React.createElement(Form, { onFinish: (data) => state.save(data), layout: "vertical", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}, "Über diesen Dialog können neue Artikel in der Datenbank registiert werden, die nicht bereits über orderbase in der Datenbank angelegt wurden."



        )
        , React.createElement(Form.Item, {
          label: "Name",
          name: "name",
          rules: [{ required: true, message: "Bitte einen Namen angeben." }], __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}

          , React.createElement(Input, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} )
        )
        , React.createElement(Form.Item, { label: "Einheit", name: "unit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
          , React.createElement(Input, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}} )
        )
        , React.createElement(Form.Item, {
          label: "Beschreibung",
          name: "description4",
          help: "Dieses Feld ist optional."   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}

          , React.createElement(Input.TextArea, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 36}} )
        )
        , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
          , React.createElement(Space, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
            , React.createElement(Button, { type: "primary", htmlType: "submit", children: "Speichern", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}} )
          )
        )
      )
    )
  );
});
