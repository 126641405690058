import "antd/dist/reset.css";

import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
dayjs.extend(isoWeek);

import { init, StorageAdapterLS } from "@opendash/core";
import { registerIconPack } from "@opendash/icons";

import { FeedbackPlugin } from "@opendash/plugin-feedback";
import { $parse, ParsePlugin } from "@opendash/plugin-parse";

import "./parse.config";

import { ElevatorsListRoute, ElevatorsViewRoute } from "./features/elevators";
import { PhoneCalendarPage } from "./pages/calendar/PhoneCalendar";
import { OrderCreatePage } from "./pages/orders/OrderCreatePage";
import { OrderDetailPage } from "./pages/orders/OrderDetailPage";
import { OrdersPage } from "./pages/orders/OrdersRoute";
import { PhoneOrderPage } from "./pages/orders/PhoneOrderPage";
import { WeekOverviewPage } from "./pages/overview/WeekOverviewPage";
import { PhoneGlobal } from "./pages/Phone/Global";
import { OfflineNotice } from "./pages/Phone/Offline";
import { PhoneSetup } from "./pages/Phone/Setup";
import { Facility, Item, Order, OrderType, TextTemplate } from "./parse";
import { $heinzerling } from "./service";

init("opendash", async (factory) => {
  const url = new URL(window.location.href);

  const isEmbedded =
    !!url.searchParams.get("embedded") ||
    window.localStorage.getItem("opendash/embedded") === "true";

  if (isEmbedded) {
    window.localStorage.setItem("opendash/embedded", "true");

    url.searchParams.delete("embedded");

    window.history.replaceState(null, "", url.href);
  }

  // Logo:

  factory.ui.setLogoImage(require("../assets/logo-icon.png"));

  if (isEmbedded) {
    factory.ui.disableHeader();
    factory.ui.disableFooter();
  }

  // Service Worker

  factory.registerServiceWorker();

  // Icons
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  // Translations:

  factory.registerLanguage("de", "Deutsch");

  factory.registerTranslationResolver(
    "de",
    "app",
    () => import("./translations/de.json")
  );

  factory.registerTranslationResolver(
    "de",
    "parse-custom",
    () => import("./translations/de/parse-custom.json")
  );

  factory.registerAntDesignTranslation(
    "de",
    // @ts-ignore
    () => import("antd/lib/locale/de_DE")
  );

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());

  await factory.use(
    new ParsePlugin({
      useLiveQueries: false,
      authLdapActive: true,
    })
  );

  await factory.use(
    new FeedbackPlugin({
      feedbackAssigned: undefined,
      feedbackURL: "https://linear.apps.openinc.dev/api/issues?team=HEI",
    })
  );

  // UI

  factory.registerGlobalComponent(PhoneGlobal);

  factory.registerStaticNavigationItem({
    id: "frontpageapp",
    group: "frontpageapp",
    label: "Außendienst",
    order: 1,
    place: "frontpage",
    icon: "fa:wrench",
    link: "/overview",
    routeCondition: "**",
    activeCondition: "/",
    color: "#676767",
  });

  factory.registerStaticNavigationItem({
    id: "admin/ordertype",
    group: "admin/parse",
    label: "Auftragstypen",
    order: 99,
    place: "sidebar",
    icon: "fa:list",
    link: "/admin/parse/Heinzerling2_OrderType",
    routeCondition: "**",
    activeCondition: "/admin/parse/Heinzerling2_OrderType",
    color: "#676767",
    permission: "can-see-administration-ordertype",
  });

  // Routes

  // if ($heinzerling.sync.enabled) {
  //   factory.registerRoute({
  //     path: "/",
  //     redirectPath: "/phone/calendar",
  //   });
  // } else {
  //   factory.registerRoute({
  //     path: "/",
  //     redirectPath: "/elevators",
  //   });
  // }

  factory.registerRoute({
    path: "/elevators",
    component: async () => ({ default: ElevatorsListRoute }),
  });

  factory.registerRoute({
    path: "/elevators/:elevatorId",
    component: async () => ({ default: ElevatorsViewRoute }),
  });

  factory.registerRoute({
    path: "/order/create",
    component: async () => ({ default: OrderCreatePage }),
  });

  factory.registerRoute({
    path: "/orders",
    component: async () => ({ default: OrdersPage }),
    offline: false,
  });

  factory.registerRoute({
    path: "/orders/:orderId",
    component: async () => ({ default: OrderDetailPage }),
  });

  factory.registerRoute({
    path: "/overview",
    component: async () => ({ default: WeekOverviewPage }),
  });

  factory.registerRoute({
    path: "/overview/:startParam/:endParam",
    component: async () => ({ default: WeekOverviewPage }),
  });

  factory.registerRoute({
    path: "/calendar",
    component: async () => ({ default: PhoneCalendarPage }),
  });

  factory.registerRoute({
    path: "/phone/calendar",
    component: async () => ({ default: PhoneCalendarPage }),
  });

  factory.registerRoute({
    path: "/phone/order/:id",
    component: async () => ({ default: PhoneOrderPage }),
  });

  factory.registerRoute({
    path: "/phone/order/:id/:tab?",
    component: async () => ({ default: PhoneOrderPage }),
  });

  factory.registerRoute({
    path: "/phone/setup",
    component: async () => ({ default: PhoneSetup }),
  });

  factory.registerRoute({
    path: "/phone/offline",
    component: async () => ({ default: OfflineNotice }),
  });

  // factory.registerStaticNavigationItem({
  //   id: "online",
  //   activeCondition: "",
  //   group: "LGzHd5idlX",
  //   label: $heinzerling.sync.getOnlineState()
  //     ? "Sie sind online"
  //     : "Sie sind offline",
  //   order: 999,
  //   place: "sidebar",
  //   routeCondition: "",
  //   icon: "fa:dot-circle",
  //   color: $heinzerling.sync.getOnlineState() ? "#92ba51" : "#b30b00",
  //   link: () => {
  //     console.log(window.navigator.onLine ? "Online" : "Offline");
  //   },
  // });

  // Parse Config

  $parse.ui.setClassConfig({
    className: "_User",
    titleFields: ["username"],
    displayFields: [
      "name",
      "username",
      "email",
      "verwaltung",
      "monteur",
      "ldap",
    ],
    createFields: [
      "name",
      "username",
      "email",
      "verwaltung",
      "monteur",
      "password",
    ],
    editFields: [
      "name",
      "username",
      "email",
      "verwaltung",
      "monteur",
      "password",
    ],
    disableACLEditing: true,
  });

  $parse.ui.setDefaultView("_User", {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: Order.className,
    titleFields: ["no"],
    displayFields: [],
    createFields: [],
    editFields: [],
    disableACLEditing: true,
  });

  $parse.ui.setDefaultView(Order.className, {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: Item.className,
    titleFields: ["name"],
    displayFields: [],
    createFields: [],
    editFields: [],
    disableACLEditing: true,
  });

  $parse.ui.setDefaultView(Item.className, {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: Facility.className,
    titleFields: ["name"],
    displayFields: [],
    createFields: [],
    editFields: [],
    disableACLEditing: true,
  });

  $parse.ui.setDefaultView(Facility.className, {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: TextTemplate.className,
    titleFields: ["title"],
    displayFields: ["title", "text"],
    createFields: ["title", "text"],
    editFields: ["title", "text"],
    defaultACL: () => {
      const userId = $parse.user.id();

      return userId
        ? {
            [userId]: { read: true, write: true },
          }
        : {};
    },
  });

  $parse.ui.setDefaultView(TextTemplate.className, {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: OrderType.className,
    titleFields: ["label"],
    displayFields: ["abbreviation", "label", "description"],
    createFields: ["abbreviation", "label", "description"],
    editFields: ["abbreviation", "label", "description"],
    disableACLEditing: true,
  });

  $parse.ui.setDefaultView(OrderType.className, {
    type: "table",
  });
}).then((app) => {
  $heinzerling.init();
});
