const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/phone/components/UpdateItemsTab.tsx";import { Button, Divider, List, Space } from "antd";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import {
  AddItemDrawer,
  AddItemState,
  CreateItemDrawer,
  CreateItemState,
  PartsListEntry,

  TabState,
} from "../../items";

export default observer(function UpdateItemsTab({ orderstate }) {
  if (!orderstate.order) throw new Error("Invalid State");

  const createitemstate = useMemo(() => new CreateItemState(), []);
  const additemstate = useMemo(() => new AddItemState(orderstate), []);

  const state = useMemo(
    () => new TabState(orderstate),
    [createitemstate.open, additemstate.open]
  );

  return (
    React.createElement(React.Fragment, null
      , React.createElement(List, {
        dataSource: state.list,
        renderItem: (ir) => {
          return (
            React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
              , React.createElement(PartsListEntry, { oir: ir, orderstate: orderstate, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}} )
            )
          );
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
      )

      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 38}} )

      , React.createElement(Space, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
        , React.createElement(Button, {
          type: "primary",
          disabled: orderstate.disabled,
          children: "Artikel hinzufügen" ,
          onClick: () => (additemstate.open = true), __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        )

        , React.createElement(Button, {
          disabled: orderstate.disabled,
          children: "Neuen Artikel anlegen"  ,
          onClick: () => (createitemstate.open = true), __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
        )
      )

      , React.createElement(CreateItemDrawer, { state: createitemstate, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}} )

      , React.createElement(AddItemDrawer, { state: additemstate, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}} )
    )
  );
});
