import * as indexdb from "idb-keyval";

export class ParseDataStore {
    __init() {this.async = 1}

   __init2() {this.__lock = new AsyncLock(true)}
   __init3() {this.__writelock = new AsyncLock(false)}
   __init4() {this.__store = new IDB("parseAlt", "parse")}
   __init5() {this.__cache = {}}
   __init6() {this.__dirty = false}

  constructor() {;ParseDataStore.prototype.__init.call(this);ParseDataStore.prototype.__init2.call(this);ParseDataStore.prototype.__init3.call(this);ParseDataStore.prototype.__init4.call(this);ParseDataStore.prototype.__init5.call(this);ParseDataStore.prototype.__init6.call(this);
    this.init();
  }

   async init() {
    try {
      console.time("ParseDataStore.init()");
      this.__cache = JSON.parse((await this.__store.get("cache")) || "{}");
      console.timeEnd("ParseDataStore.init()");
    } catch (error) {
      console.error("ParseDataStore.init() Error:", error);
    } finally {
      this.__lock.unlock();
      this.write();
    }
  }

   async write(once = false) {
    await this.__writelock.wait();

    if (this.__dirty) {
      console.time("ParseDataStore.write()");
      this.__writelock.lock();
      this.__dirty = false;
      const cache = JSON.stringify(this.__cache);
      await this.__store.set("cache", cache);
      this.__writelock.unlock();
      console.timeEnd("ParseDataStore.write()");
    }

    if (!once) {
      setTimeout(() => {
        this.write();
      }, 1000);
    }
  }

   isImportantKey(key) {
    return key.startsWith("Parse/heinzerling/");
  }

   async getItemAsync(path) {
    await this.__lock.wait();

    return this.__cache[path];
  }

   async setItemAsync(path, value) {
    await this.__lock.wait();

    this.__cache[path] = value;
    this.__dirty = true;

    if (this.isImportantKey(path)) {
      await this.write(true);
    }
  }

   async removeItemAsync(path) {
    await this.__lock.wait();

    delete this.__cache[path];
    this.__dirty = true;

    if (this.isImportantKey(path)) {
      await this.write(true);
    }
  }

   async getAllKeysAsync() {
    await this.__lock.wait();

    return Object.keys(this.__cache);
  }

   async clear() {
    await this.__lock.wait();

    this.__cache = {};

    await this.__store.clear();
  }
}

class AsyncLock {
  
   __init7() {this.resolver = null}

  constructor(locked = false) {;AsyncLock.prototype.__init7.call(this);
    if (locked) {
      this.lock();
    }
  }

  lock() {
    this.promise = new Promise((resolve) => {
      this.resolver = resolve;
    });
  }

  unlock() {
    if (this.resolver) this.resolver();
  }

  wait() {
    if (this.promise) return this.promise;

    return Promise.resolve();
  }
}

class IDB {
  

  constructor(dbName, storeName) {
    this.store = indexdb.createStore(dbName, storeName);
  }

  get(key) {
    return indexdb.get(key, this.store);
  }

  set(key, value) {
    return indexdb.set(key, value, this.store);
  }

  keys() {
    return indexdb.keys(this.store);
  }

  clear() {
    return indexdb.clear(this.store);
  }
}
