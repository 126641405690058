import { makeAutoObservable, runInAction } from "mobx";
import Parse from "parse";

import { $offlineService } from "../features/offline";
import { formatDateTime } from "../helper/formatter";
import {
  ContactPerson,
  Customer,
  DeletedObjects,
  Facility,
  Item,
  Order,
  OrderFacilityRelation,
  OrderImage,
  OrderItemRelation,
  OrderTechnicanRelation,
  OrderType,
  Technican,
  TextTemplate,
} from "../parse";

const lse_key = "heinzerling/offline";
const lss_key = "heinzerling/offline-last-sync";

export class HeinzerlingOfflineService {
  

   __init() {this.loading = true}
   __init2() {this.enabled = !!window.localStorage.getItem(lse_key) || false}
   __init3() {this.lastSync =
    parseInt(window.localStorage.getItem(lss_key) || "0") || 0}
   __init4() {this._log = []}

  constructor(app) {;HeinzerlingOfflineService.prototype.__init.call(this);HeinzerlingOfflineService.prototype.__init2.call(this);HeinzerlingOfflineService.prototype.__init3.call(this);HeinzerlingOfflineService.prototype.__init4.call(this);
    makeAutoObservable(this);
    this.app = app;

    // this.setEnabled(true);
    this.init();
  }

   setEnabled(value) {
    this.enabled = value;
    if (value) {
      window.localStorage.setItem(lse_key, "on");

      this.init();
    } else {
      window.localStorage.removeItem(lse_key);
      this.setLastSync(0);

      Parse.User.unPinAllObjects();
      Parse.Object.unPinAllObjects();
    }
  }

   setLastSync(value) {
    this.lastSync = value;

    if (value) {
      window.localStorage.setItem(lss_key, value.toString());
    } else {
      window.localStorage.removeItem(lss_key);
    }
  }

   createQuery(
    cls
  ) {
    const query = new Parse.Query(cls);

    return this.patchQuery(query);
  }

   patchQuery(
    query
  ) {
    if (this.enabled) {
      query.fromLocalDatastore();
    }

    query.limit(1000000);

    return query;
  }

   async saveObject(object) {
    // If online save normally
    if ($offlineService.online) {
      await object.save();
    }

    // If offline put to queue
    if (!$offlineService.online) {
      $offlineService.jobQueue.push({
        object: object,
        action: "create",
      });
    }

    if (this.enabled) {
      await object.pin();
    }
  }

   async deleteObject(object) {
    if ($offlineService.online) {
      await object.destroy();
    }

    if (!$offlineService.online) {
      $offlineService.jobQueue.push({
        object: object,
        action: "delete",
      });
    }

    if (this.enabled) {
      await object.unPin();
    }
  }

   async getUser() {
    if ($offlineService.online) {
      return await Parse.User.currentAsync();
    }

    if (!$offlineService.online) {
      return await new Parse.Query(Parse.User)
        .fromPinWithName("CurrentUser")
        .first();
    }
    return undefined;
  }

   async init() {
    this.loading = true;
    this._log.length = 0;

    this.log("Synchronisation wird vorbereitet.");

    const user = await Parse.User.currentAsync();
    const now = Date.now();

    if (!this.enabled || !user) {
      runInAction(() => {
        this.loading = false;
      });

      this.log("Synchronisation wird abgebrochen.");
      return;
    }

    const start = Date.now();

    if (this.lastSync) {
      this.log(
        `Letzte Synchronisation fand am ${formatDateTime(
          new Date(this.lastSync)
        )} statt.`
      );
    } else {
      await Parse.Object.unPinAllObjects();
    }

    this.log("Synchronisation gestartet.");

    //Pin current user
    await user.pinWithName("CurrentUser");

    const classNames = [
      ContactPerson,
      Customer,
      Facility,
      Item,
      Order,
      OrderImage,
      OrderItemRelation,
      OrderTechnicanRelation,
      OrderFacilityRelation,
      Technican,
      TextTemplate,
      OrderType,
    ];

    for (const cls of classNames) {
      const query = new Parse.Query(cls).limit(1000000);

      if (this.lastSync) {
        query.greaterThan("updatedAt", new Date(this.lastSync));
      }

      const objects = await query.find();

      const cName = cls.className
        .replace("Heinzerling_", "")
        .replace("Heinzerling2_", "");

      this.log(`Synchronisation von ${cName}, ${objects.length} neue Elemente`);

      await Parse.Object.pinAll(objects);
    }

    if (this.lastSync) {
      this.log("Entferne gelöschte Elemente.");

      const deletedObjects = await new Parse.Query(DeletedObjects)
        .limit(1000000)
        .find();

      for (const o of deletedObjects) {
        try {
          const obj = await new Parse.Query(o.c).get(o.i);

          if (obj) {
            await obj.unPin();
          }
        } catch (error) {}
      }

      // await Parse.Object.unPinAll(
      //   deletedObjects.map((o) => new Parse.Object(o.c, { id: o.i }))
      // );

      this.log("Gelöschte Elemente wurden entfernt.");
    }

    this.setLastSync(now);

    this.setLoading(false);

    const ms = Date.now() - start;

    this.log(`Synchronisation in ${ms}ms abgeschlossen.`);
  }

   log(row) {
    this._log.push("> " + row);
  }

  setLoading(v) {
    this.loading = v;
  }
}
