import Parse from "parse";











export class TextTemplate extends Parse.Object {
  static __initStatic() {this.className = "Heinzerling2_TextTemplate"}

  constructor(data) {
    super("Heinzerling2_TextTemplate", data );
  }

  get text() {
    return super.get("text");
  }
  set text(value) {
    super.set("text", value);
  }
  get title() {
    return super.get("title");
  }
  set title(value) {
    super.set("title", value);
  }
} TextTemplate.__initStatic();

Parse.Object.registerSubclass("Heinzerling2_TextTemplate", TextTemplate);
