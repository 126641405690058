import Parse from "parse";


















export class OrderImage extends Parse.Object {
  static __initStatic() {this.className = "Heinzerling2_OrderImage"}

  constructor(data) {
    super("Heinzerling2_OrderImage", data );
  }

  get deleted() {
    return super.get("deleted");
  }
  set deleted(value) {
    super.set("deleted", value);
  }
  get image() {
    return super.get("image");
  }
  set image(value) {
    super.set("image", value);
  }
  get imageBase64() {
    return super.get("imageBase64");
  }
  set imageBase64(value) {
    super.set("imageBase64", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get order() {
    return super.get("order");
  }
  set order(value) {
    super.set("order", value);
  }
  get thumbnail() {
    return super.get("thumbnail");
  }
  set thumbnail(value) {
    super.set("thumbnail", value);
  }
  get writtenToDisk() {
    return super.get("writtenToDisk");
  }
  set writtenToDisk(value) {
    super.set("writtenToDisk", value);
  }
} OrderImage.__initStatic();

Parse.Object.registerSubclass("Heinzerling2_OrderImage", OrderImage);
