const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/phone/components/WeeklyHeader.tsx";import { useTranslation } from "@opendash/core";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import React from "react";
import { createGlobalStyle } from "styled-components";


const GlobalStyle = createGlobalStyle`
  .ant-picker {
    padding: 15px;
    margin-bottom: 0 !important;
  }
  .ant-picker-panel-container,
  .ant-picker-panel,
  .ant-picker-date-panel,
  .ant-picker-week-panel {
    width: 100% !important;
  }
  .ant-picker-date-panel .ant-picker-content{
    width: 100% !important;
  }
`;

export default observer(({ start, end, onChange }) => {
  const t = useTranslation();

  return (
    React.createElement(React.Fragment, null
      /* <GlobalStyle /> */
      , React.createElement(DatePicker, {
        value: dayjs(start),
        size: "large",
        // picker="week"
        // format={(value: Dayjs) => {
        //   return "Woche " + value.format("WW in YYYY");
        // }}
        style: { width: "100%", marginBottom: 30 },
        onChange: (v) => {
          if (v) {
            onChange(v.startOf("day").toDate(), v.endOf("day").toDate());
          }
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
      )
    )
  );
});
