 function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { message } from "antd";
import { makeAutoObservable, runInAction } from "mobx";
import Parse from "parse";
import { OrderTechnicanRelation, Technican } from "../../../parse";
import { $heinzerling } from "../../../service";


export class UpdateTechnicansState {
  

   __init() {this.updateDialog = null}
   __init2() {this.updateDialogTechnican = null}
   __init3() {this.updateDialogDate = null}
   __init4() {this.updateDialogHours = null}
   __init5() {this.updateDialogOutsideBusinessHours = false}
   __init6() {this.updateDialogHideFromCustomer = false}

   __init7() {this.technicans = []}
   __init8() {this.listChange = 1}

  constructor(state) {;UpdateTechnicansState.prototype.__init.call(this);UpdateTechnicansState.prototype.__init2.call(this);UpdateTechnicansState.prototype.__init3.call(this);UpdateTechnicansState.prototype.__init4.call(this);UpdateTechnicansState.prototype.__init5.call(this);UpdateTechnicansState.prototype.__init6.call(this);UpdateTechnicansState.prototype.__init7.call(this);UpdateTechnicansState.prototype.__init8.call(this);
    this.state = state;

    makeAutoObservable(this);
  }

  get list() {
    this.listChange;

    return this.state.technicans
      .filter((x) => !x.local_deleted)
      .sort((a, b) => (_optionalChain([a, 'access', _ => _.start, 'optionalAccess', _2 => _2.valueOf, 'call', _3 => _3()]) || 0) - (_optionalChain([b, 'access', _4 => _4.start, 'optionalAccess', _5 => _5.valueOf, 'call', _6 => _6()]) || 0));
  }

  get technicanOptions() {
    return this.technicans.map((t) => ({
      value: t.id,
      label: t.name,
    }));
  }

   setUpdateDialog(ir) {
    this.updateDialog = ir;

    this.setUpdateDialogTechnican(_optionalChain([ir, 'optionalAccess', _7 => _7.technican]) || null);
    this.setUpdateDialogDate(_optionalChain([ir, 'optionalAccess', _8 => _8.start]) || null);
    this.setUpdateDialogHours(_nullishCoalesce(_nullishCoalesce(_optionalChain([ir, 'optionalAccess', _9 => _9.local_hours]), () => ( _optionalChain([ir, 'optionalAccess', _10 => _10.hours]))), () => ( 0)));
    this.setUpdateDialogOutsideBusinessHours(_nullishCoalesce(_optionalChain([ir, 'optionalAccess', _11 => _11.outsideBusinessHours]), () => ( false)));
    this.setUpdateDialogHideFromCustomer(_nullishCoalesce(_optionalChain([ir, 'optionalAccess', _12 => _12.local_hideFromCustomer]), () => ( false)));

    this.fetchTechnicans();
  }

   setUpdateDialogTechnican(
    v
  ) {
    if (typeof v === "string") {
      const item = this.technicans.find((i) => i.id === v) || null;

      this.updateDialogTechnican = item;
    } else {
      this.updateDialogTechnican = v;
    }
  }

   setUpdateDialogHours(v) {
    this.updateDialogHours = v;
  }

   setUpdateDialogDate(v) {
    this.updateDialogDate = v;
  }

   setUpdateDialogOutsideBusinessHours(
    v
  ) {
    this.updateDialogOutsideBusinessHours = v;
  }

   setUpdateDialogHideFromCustomer(
    v
  ) {
    this.updateDialogHideFromCustomer = v;
  }

   async create() {
    if (!this.state.order) throw new Error("Invalid State");

    const user = await $heinzerling.sync.getUser();
    if (typeof user === "undefined" || user === null) {
      throw new Error("Invalid State");
    }

    const now = new Date();

    const item = new OrderTechnicanRelation({
      order: this.state.order,
      technican: _optionalChain([user, 'optionalAccess', _13 => _13.get, 'call', _14 => _14("technican")]),
      start: now,
      outsideBusinessHours: now.getHours() > 20,
      local_hours: 1,
    });

    runInAction(() => {
      this.state.technicans.push(item);
    });

    this.setUpdateDialog(item);
  }

   async saveUpdateDialog() {
    if (!this.updateDialog) return null;

    if (this.updateDialogTechnican === null) {
      return message.error("Es muss ein Artikel ausgewählt werden!");
    }

    if (this.updateDialogDate === null) {
      return message.error("Es muss ein Datum gewählt werden!");
    }

    if (this.updateDialogHours === null) {
      return message.error("Es müssen Stunden eingegeben werden!");
    }

    if (this.updateDialogOutsideBusinessHours === null) {
      return message.error(
        "Es muss ausgewählt werden, ob die Stunden außerhalb der Geschäftszeiten liegen!"
      );
    }

    if (this.updateDialogHideFromCustomer === null) {
      return message.error(
        "Es muss ausgewählt werden, ob die Stunden für den Kunden sichtbar sind!"
      );
    }

    try {
      this.updateDialog.set("technican", this.updateDialogTechnican);
      this.updateDialog.set("start", this.updateDialogDate);
      this.updateDialog.set("local_hours", this.updateDialogHours);
      this.updateDialog.set(
        "outsideBusinessHours",
        this.updateDialogOutsideBusinessHours
      );

      this.updateDialog.set(
        "local_hideFromCustomer",
        this.updateDialogHideFromCustomer
      );

      await $heinzerling.sync.saveObject(this.updateDialog);

      message.success("Eintrag wurde gespeichert.");

      runInAction(() => {
        this.updateDialog = null;
        this.updateDialogTechnican = null;
        this.updateDialogHours = null;
        this.updateDialogDate = null;
        this.updateDialogOutsideBusinessHours = false;
      });
    } catch (error) {
      if (error instanceof Parse.Error) {
        message.error(
          "Speichern fehlgeschlagen: " + error.code + ": " + error.message
        );
      } else {
        message.error("Speichern fehlgeschlagen: Unbekannter Fehler");
      }
    }
    return null;
  }

   async destoryUpdateDialog() {
    if (this.updateDialog) {
      this.updateDialog.set("local_hours", 0);

      await $heinzerling.sync.saveObject(this.updateDialog);

      //Can't delete object, because orderbase sync does not allow for deletion yet
      // await $heinzerling.sync.deleteObject(this.updateDialog);

      this.setUpdateDialog(null);

      message.success("Eintrag gelöscht.");

      runInAction(() => {
        this.listChange += 1;
      });
    }
  }

   async fetchTechnicans() {
    if (this.technicans.length > 0) return;

    const technicans = await $heinzerling.sync
      .createQuery(Technican)
      .ascending("name")
      .limit(1000000)
      .find();

    runInAction(() => {
      this.technicans = technicans;
    });
  }
}
