const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/pages/Phone/Global.tsx";import { observer } from "mobx-react-lite";
import React from "react";
import { SignatureDrawer } from "../../features/signature";
import { $heinzerling } from "../../service";

export const PhoneGlobal = observer(() => {
  if (!$heinzerling.mobile.signIds) {
    return null;
  }

  return React.createElement(SignatureDrawer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 11}} );
});
