import Parse from "parse";
import { ParseDataStore } from "./parse.ds";

// @ts-ignore // "hidden" feature for bigger offline sync
Parse.CoreManager.setStorageController(new ParseDataStore());

Parse.initialize("heinzerling");
Parse.enableLocalDatastore();

switch (window.location.hostname) {
  case "heinzerling.openinc.dev":
    Parse.serverURL = "https://heinzerling-parse-server.openinc.dev/parse";
    break;

  case "heinzerling-dev.openinc.dev":
  case "localhost":
  case "10.10.10.10":
  case "127.0.0.1":
  default:
    Parse.serverURL = "https://heinzerling-parse-server-dev.openinc.dev/parse";
    break;
}
