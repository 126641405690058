const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/pages/orders/OrderCreatePage.tsx"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { AdminLayout, Loading } from "@opendash/core";
import { Button, DatePicker, Form, Select, message } from "antd";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import { $offlineService } from "../../features/offline";
import { OrderCreateState } from "../../features/orders";
import {
  Facility,
  Order,
  OrderTechnicanRelation,
  Technican,
} from "../../parse";
import { $heinzerling } from "../../service";

export const OrderCreatePage = observer(() => {
  const navigate = useNavigate();
  const state = useMemo(() => new OrderCreateState(), []);
  const [form] = Form.useForm();

  function getSelectedFacilityInfo() {
    if (state.SelectedFacility) {
      return (
        React.createElement(React.Fragment, null
          , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}, "Der Auftrag wird dem folgenden Kunden zugewiesen:"
                  , " "
            , _optionalChain([state, 'access', _ => _.SelectedFacility, 'access', _2 => _2.get, 'call', _3 => _3("customer"), 'optionalAccess', _4 => _4.get, 'call', _5 => _5("name")])
          )
        )
      );
    }
    return false;
  }

  function submit() {
    form.validateFields().then(async (values) => {
      const facility = await $heinzerling.sync
        .createQuery(Facility)
        .include("customer")
        .get(values["facility"]);

      const neworder = new Order();

      neworder.set("local_createdOnMobile", true);
      neworder.set("facility", facility);
      neworder.set("type", values["type"]);
      neworder.set("date", dayjs(values["dateandtime"]).toDate());
      neworder.set("no", "local-" + Date.now()); //TODO
      neworder.set("customer", facility.get("customer"));

      await $heinzerling.sync.saveObject(neworder);

      for (const technicianId of values.technicians) {
        const technician = await $heinzerling.sync
          .createQuery(Technican)
          .get(technicianId);

        const newordertechnicianrelation = new OrderTechnicanRelation();
        newordertechnicianrelation.set("technican", technician);
        newordertechnicianrelation.set("order", neworder);
        newordertechnicianrelation.set("local_hours", 1);
        newordertechnicianrelation.set(
          "start",
          dayjs(values["dateandtime"]).toDate()
        );

        await $heinzerling.sync.saveObject(newordertechnicianrelation);
      }

      form.resetFields();
      message.success(
        "Auftrag erfolgreich angelegt. Sie werden weitergeleitet.",
        5
      );

      if ($offlineService.online) {
        navigate(`/phone/order/${neworder.id}/view-overview`);
      } else {
        navigate("/phone/offline?action=order_created");
      }
    });
  }

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
      , state.loading ? (
        React.createElement(Loading, { message: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}} )
      ) : (
        React.createElement(Form, { form: form, layout: "vertical", __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
          , React.createElement(Form.Item, {
            name: "facility",
            label: "Aufzug",
            rules: [{ required: true }], __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}

            , React.createElement(Select, {
              showSearch: true,
              options: state.facilitiesForSelect,
              filterOption: (input, option) => {
                return (_nullishCoalesce(_optionalChain([option, 'optionalAccess', _6 => _6.label]), () => ( "")))
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              },
              onSelect: (value, option) => {
                const foundfacility = state.facilities.filter(
                  (facility) => facility.id === value
                )[0];

                if (foundfacility) {
                  state.SelectedFacility = foundfacility;
                }
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
            )
          )

          , React.createElement(Form.Item, {
            name: "type",
            label: "Auftragsart",
            rules: [{ required: true }], __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}

            , React.createElement(Select, {
              options: state.orderTypes.map((ordertype) => ({
                value: ordertype.get("abbreviation"),
                label: `${ordertype.get("abbreviation")} - ${ordertype.get(
                  "label"
                )}`,
              })), __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
            )
          )
          , React.createElement(Form.Item, {
            name: "dateandtime",
            label: "Datum und Zeit"  ,
            rules: [{ required: true }],
            initialValue: dayjs().startOf("hour"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}

            , React.createElement(DatePicker, {
              showTime: { format: "HH:mm" },
              format: "DD.MM.YYYY HH:mm" ,
              onChange: () => {},
              onOk: () => {},
              style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
            )
          )
          , React.createElement(Form.Item, {
            name: "technicians",
            label: "Monteur",
            initialValue: 
              state.userTechnician ? [state.userTechnician.id] : null
            ,
            rules: [{ required: true }], __self: this, __source: {fileName: _jsxFileName, lineNumber: 145}}

            , React.createElement(Select, {
              mode: "multiple",
              showSearch: true,
              options: state.TechniciansForSelect,
              filterOption: (input, option) => {
                return (_nullishCoalesce(_optionalChain([option, 'optionalAccess', _7 => _7.label]), () => ( "")))
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 153}}
            )
          )

          , getSelectedFacilityInfo()

          , React.createElement(Button, { type: "primary", onClick: () => submit(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 168}}, "Auftrag anlegen"

          )
        )
      )
    )
  );
});
