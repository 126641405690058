import Parse from "parse";












export class OrderType extends Parse.Object {
  static __initStatic() {this.className = "Heinzerling2_OrderType"}

  constructor(data) {
    super("Heinzerling2_OrderType", data );
  }

  get abbreviation() {
    return super.get("abbreviation");
  }
  set abbreviation(value) {
    super.set("abbreviation", value);
  }
  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
} OrderType.__initStatic();

Parse.Object.registerSubclass("Heinzerling2_OrderType", OrderType);
