const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/pages/Phone/Setup.tsx";import { AdminLayout } from "@opendash/core";
import { Button } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { $offlineService } from "../../features/offline";
import { $heinzerling } from "../../service";

const Code = styled.pre`
  padding: 16px;
  font-size: 85%;
  background: hsla(0, 0%, 58.8%, 0.1);
  border: 1px solid hsla(0, 0%, 39.2%, 0.2);
  border-radius: 3px;
  margin-bottom: 20px;
`;

export const PhoneSetup = observer(() => {
  const enabled = $heinzerling.sync.enabled;

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
      , React.createElement('p', { style: { textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}, "Offline Synchronisation ist "
           , enabled ? "aktiviert" : "deaktiviert", "."
      )
      , React.createElement('p', { style: { textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}, "Sie sind aktuell "
           , $offlineService.online ? "online" : "offline", "."
      )

      , enabled && React.createElement(Code, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}, $heinzerling.sync._log.join("\n"))

      , $offlineService.online ? null : (
        React.createElement(React.Fragment, null
          , React.createElement('p', { style: { textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}, "Folgende Jobs müssen synchronisiert werden."

          )
          , $offlineService.jobQueue.length === 0 ? (
            React.createElement(Code, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}, "...keine Jobs" )
          ) : (
            React.createElement(Code, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
              , $offlineService.jobQueue
                .map((job) => {
                  return `${job.object.className} ${job.action}`;
                })
                .join("\n")
            )
          )
        )
      )

      , enabled && (
        React.createElement(Button, {
          children: "Synchronisation starten" ,
          disabled: $heinzerling.sync.loading,
          onClick: () => {
            $heinzerling.sync.init();
          },
          style: {
            width: "100%",
            marginBottom: 20,
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
        )
      )

      , enabled && (
        React.createElement(Button, {
          children: "Komplette Synchronisation starten"  ,
          disabled: $heinzerling.sync.loading,
          onClick: () => {
            $heinzerling.sync.setLastSync(0);
            $heinzerling.sync.init();
          },
          style: {
            width: "100%",
            marginBottom: 20,
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
        )
      )

      , React.createElement(Button, {
        type: "primary",
        children: enabled ? "Deaktivieren" : "Aktivieren",
        onClick: () => {
          $heinzerling.sync.setEnabled(!enabled);
        },
        style: {
          width: "100%",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
      )
    )
  );
});
