import { observer } from "mobx-react-lite";
import React from "react";
import { $offlineService } from "..";







export const Offline = observer((props) => {
  $offlineService.addChangeHandler(props.onChange);

  if ($offlineService.online && props.onlineChildren) {
    return React.createElement(React.Fragment, null, props.onlineChildren);
  }

  if ($offlineService.online && !props.onlineChildren) {
    return null;
  }

  return React.createElement(React.Fragment, null, props.children);
});
