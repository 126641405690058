const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/orders/components/PrintView.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation } from "@opendash/i18n";
import { Button } from "antd";
import * as React from "react";

import { $parse } from "@opendash/plugin-parse";
import styled from "styled-components";


const Container = styled.div`
  width: 100%;
`;

const Actions = styled.div`
  margin-bottom: 20px;
  @media (max-width: 600px) {
    button {
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  @media (min-width: 601px) {
    button {
      margin-right: 10px;
    }
    .mobile-only {
      display: none;
    }
  }
`;

const Paper = styled.div`
  iframe {
    width: 100%;
    border: 0;
  }

  @media screen {
    max-width: 1000px;
    margin: 10px auto;

    padding: 10px;

    background: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export default React.memo(function PrintView({
  title,
  preview,
  download,
}) {
  const t = useTranslation();

  const session = $parse.user.sessionToken();
  const qs = `?session=${session}`;

  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
      , React.createElement(Actions, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
        , title && React.createElement('h1', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}, title)
        , download && (
          React.createElement(Button, {
            type: "primary",
            children: t("app:print.action"),
            onClick: () => {
              // window.print();

              window.open(download + qs, "_blank");
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
          )
        )

        , preview && (
          React.createElement(Button, {
            className: "mobile-only",
            type: "primary",
            children: t("app:print.action_preview"),
            onClick: () => {
              // window.print();

              window.open(preview + qs, "_blank");
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
          )
        )
      )
      , React.createElement(Paper, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
        , React.createElement('iframe', {
          src: preview + qs,
          onLoad: (e) => {
            const frame = e.target ;

            frame.height = (frame.offsetWidth * 10) / 7 + "px";
            // frame.width = frame.offsetWidth + "px";

            try {
              frame.height =
                _optionalChain([frame, 'optionalAccess', _ => _.contentWindow, 'optionalAccess', _2 => _2.document, 'access', _3 => _3.body, 'access', _4 => _4.scrollHeight]) + "px";
            } catch (error) {
              // CORS Error
            }
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
        )
      )
    )
  );
});
