import Parse from "parse";






















export class ContactPerson extends Parse.Object {
  static __initStatic() {this.className = "Heinzerling2_ContactPerson"}

  constructor(data) {
    super("Heinzerling2_ContactPerson", data );
  }

  get customer() {
    return super.get("customer");
  }
  set customer(value) {
    super.set("customer", value);
  }
  get email() {
    return super.get("email");
  }
  set email(value) {
    super.set("email", value);
  }
  get facility() {
    return super.get("facility");
  }
  set facility(value) {
    super.set("facility", value);
  }
  get mobile() {
    return super.get("mobile");
  }
  set mobile(value) {
    super.set("mobile", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get phone() {
    return super.get("phone");
  }
  set phone(value) {
    super.set("phone", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted() {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value) {
    super.set("sqlDeleted", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
} ContactPerson.__initStatic();

Parse.Object.registerSubclass("Heinzerling2_ContactPerson", ContactPerson);
