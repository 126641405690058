const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/components/ErrorMessage.tsx";import { Icon } from "@opendash/icons";
import React, { memo } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 200px;

  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
`;

const IconHolder = styled.div`
  font-size: 2em;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-weight: 700;
`;

const Message = styled.div`
  margin-bottom: 10px;
`;








export const ErrorMessage = memo(function ErrorMessage({
  icon,
  iconClass,
  title,
  message,
}) {
  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
      , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
        , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
          , React.createElement(Icon, { icon: icon, className: iconClass, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}} )
        )
        , React.createElement(Title, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}, title)
        , React.createElement(Message, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}, message)
      )
    )
  );
});
