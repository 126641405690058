const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/orders/components/OrderMedia.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation } from "@opendash/core";
import { Card, Divider, Image, List } from "antd";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

import { OrderImage } from "../../../parse";
import { $heinzerling } from "../../../service";

export default observer(({ order }) => {
  const t = useTranslation();
  const [images, setImages] = useState([]);

  useMemo(
    () =>
      $heinzerling.sync
        .createQuery(OrderImage)
        .equalTo("order", order)
        .notEqualTo("deleted", true)
        .ascending("createdAt")
        .find(),
    [_optionalChain([order, 'optionalAccess', _ => _.id])]
  ).then(setImages);

  if (images.length === 0) {
    return null;
  }

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} )

      , React.createElement(Image.PreviewGroup, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
        , React.createElement(List, {
          grid: {
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 5,
            xxl: 6,
          },
          rowKey: (row) => row.id,
          dataSource: images,
          renderItem: (image) => {
            const alt = image.get("name");
            const src = _optionalChain([image, 'access', _2 => _2.get, 'call', _3 => _3("image"), 'optionalAccess', _4 => _4.url, 'call', _5 => _5()]);
            return (
              React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
                , React.createElement(Card, {
                  hoverable: true,
                  cover: React.createElement(Image, { alt: alt, src: src, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} ),
                  children: 
                    React.createElement(Card.Meta, {
                      title: alt,
                      description: t("app:orders.fotos.card_description"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
                    )
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
                )
              )
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
        )
      )
    )
  );
});
