const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/phone/components/HistoryTab.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Icon } from "@opendash/icons";
import { Alert, Collapse, Empty, Tag, Timeline } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { Offline } from "../../offline/components/Offline";


export default observer(function HistoryTab({ state }) {
  return (
    React.createElement(React.Fragment, null
      , React.createElement(Offline, {
        children: 
          React.createElement(Alert, {
            message: "Sie sind offline"  ,
            type: "warning",
            description: "Es werden eventuell nicht alle Daten angezeigt."      ,
            style: { marginBottom: 12 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
          )
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
      )
      , React.createElement(Collapse, {
        items: state.history.map((historyitem) => {
          const timelineitems = historyitem.orders
            .filter((order) => order.get("no") !== _optionalChain([state, 'access', _ => _.order, 'optionalAccess', _2 => _2.get, 'call', _3 => _3("no")]))
            .map((order) => {
              return {
                key: order.id,
                label: (
                  React.createElement(React.Fragment, null
                    , new Date(order.get("date")).toLocaleDateString("de-de"), " @" , " "
                    , new Date(order.get("date")).toLocaleString("de-de", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  )
                ),
                children: (
                  React.createElement(React.Fragment, null
                    , React.createElement(Tag, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}, order.get("type"))

                    , React.createElement(Link, { to: `/phone/order/${order.id}/view-overview`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}, "Auftrag "
                       , order.get("no"), " " , React.createElement(Icon, { icon: "fa:external-link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}} )
                    )

                    , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 46}} )

                    , order.get("introductionText") &&
                    order.get("introductionText").length > 0 ? (
                      React.createElement(React.Fragment, null
                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}} )
                        , order.get("introductionText")
                      )
                    ) : null
                    , order.get("orderText") &&
                    order.get("orderText").length > 0 ? (
                      React.createElement(React.Fragment, null
                        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 58}} )
                        , order.get("orderText")
                      )
                    ) : null

                    , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 63}} )

                    , order.get("sys_pdf") ? (
                      React.createElement(Link, { to: order.get("sys_pdf").url(), target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}, "Auftragsbericht "
                         , React.createElement(Icon, { icon: "fa:external-link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}} )
                      )
                    ) : null
                  )
                ),
              };
            });
          return {
            key: historyitem.no,
            label: `${historyitem.no} (${historyitem.street}, ${historyitem.postcode} ${historyitem.city})`,
            children: (
              React.createElement(React.Fragment, null
                /* <Descriptions
                bordered
                size="small"
                style={{ marginBottom: 30 }}
                column={1}
                items={[
                  {
                    label: t("app:classes.Facility.fields.no"),
                    children: facility.no,
                    },
                    ]}
              /> */
                , timelineitems.length === 0 ? (
                  React.createElement(Empty, { description: "Keine Historie" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}} )
                ) : (
                  React.createElement(Timeline, { mode: "left", items: timelineitems, __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}} )
                )
              )
            ),
          };
        }),
        defaultActiveKey: state.history.length > 0 ? state.history[0].no : "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
      )
    )
  );
});
