import { $parse } from "@opendash/plugin-parse";
import { makeAutoObservable } from "mobx";



export class HeinzerlingMobileService {
   __init() {this.signIds = null}

  constructor(app) {;HeinzerlingMobileService.prototype.__init.call(this);
    makeAutoObservable(this);
  }

  signOrders(ids) {
    this.signIds = ids;
  }

  isOrderReadOnly(order) {
    if (!order) {
      return true;
    }

    if (
      order.local_signatureTimestamp ||
      order.local_signatureCustomer ||
      order.local_signatureTechnican
    ) {
      return true;
    }

    return !$parse.ui.getObjectPermission(order, "update");
  }
}
