const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/phone/components/NavigationTab.tsx";import { Icon } from "@opendash/icons";
import { Button, List } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";

import { formatAddress } from "../../../helper/formatter";

export default observer(function NavigationTab({ state }) {
  return (
    React.createElement(List, {
      dataSource: state.facilities,
      renderItem: (facility) => {
        const address = formatAddress(facility, true) || "";

        return (
          React.createElement(List.Item, {
            actions: [
              React.createElement(Button, {
                disabled: !address,
                onClick: () => {
                  const params = new URLSearchParams();

                  params.set("api", "1");
                  params.set("destination", address);
                  params.set("travelmode ", "driving");
                  params.set("dir_action ", "navigate");

                  const url =
                    "https://www.google.com/maps/dir/?" + params.toString();

                  console.log(url);
                  window.open(url);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}

                , React.createElement(Icon, { icon: "fa:map-marked-alt", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}} )
              ),
            ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}

            , React.createElement(List.Item.Meta, { title: facility.name, description: address, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}} )
          )
        );
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
    )
  );
});
