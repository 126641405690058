const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/orders/components/PageActions.tsx";"use client";
import { Icon } from "@opendash/icons";
import { Button, Col, Row, Space, Typography } from "antd";
import React from "react";


const { Text } = Typography;

export default (props) => {
  return (
    React.createElement(React.Fragment, null
      , React.createElement(Row, { style: { marginBottom: 24 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
        , React.createElement(Col, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
          , React.createElement(Row, { gutter: 8, align: "middle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
            , props.onBack ? (
              React.createElement(Col, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
                , React.createElement(Button, {
                  type: "text",
                  icon: React.createElement(Icon, { icon: "fa:arrow-left", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} ),
                  onClick: () => props.onBack(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
                )
              )
            ) : null
            , React.createElement(Col, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
              , React.createElement(Text, { strong: true, style: { fontSize: "1.3rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
                , props.title
              )
            )
            , React.createElement(Col, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
              , props.subTitle ? (
                React.createElement(Text, { type: "secondary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}, props.subTitle)
              ) : null
            )
            , props.footer ? React.createElement(Col, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}, props.footer) : null
          )
        )
        , React.createElement(Col, { flex: "auto", style: { textAlign: "right" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
          , React.createElement(Space, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}, props.extra)
        )
      )
      , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        , React.Children.map(props.children, (child, index) => (
          React.createElement('span', { key: index, className: "page-actions_meta", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
            , child
          )
        ))
      )
    )
  );
};
