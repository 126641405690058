const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/phone/components/UpdateTextsTab.tsx";import { Icon } from "@opendash/icons";
import { Button, Checkbox, Drawer, Form, Input, List, Space } from "antd";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { UpdateTextsState, } from "..";

export default observer(function UpdateTextsTab({
  state,
}) {
  if (!state.order) throw new Error("Invalid State");

  const x = React.useMemo(() => new UpdateTextsState(state.order), []);
  const [editFinishedText, setEditFinishedText] = React.useState(false);
  const [editDocumentText, setEditDocumentText] = React.useState(false);
  return (
    React.createElement(React.Fragment, null
      , React.createElement(Form, { layout: "vertical", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
        , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
          , React.createElement(Checkbox, {
            children: "Weitere Arbeiten gewünscht oder erforderlich. Details bei Bemerkungen eintragen."        ,
            disabled: state.disabled,
            checked: x.local_moreWorkNecessary,
            onChange: (e) =>
              runInAction(() => {
                x.local_moreWorkNecessary = e.target.checked;
                x.save();
                //x.dirty = true;
              })
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
          )
        )
        , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
          , React.createElement(Checkbox, {
            children: "Notdiensteinsatz",
            disabled: state.disabled,
            checked: x.local_emergencyService,
            onChange: (e) =>
              runInAction(() => {
                x.local_emergencyService = e.target.checked;
                x.save();
                //x.dirty = true;
              })
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
          )
        )
        , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
          , React.createElement(Checkbox, {
            children: "Äußere Gewalteinwirkung" ,
            disabled: state.disabled,
            checked: x.local_vandalism,
            onChange: (e) =>
              runInAction(() => {
                x.local_vandalism = e.target.checked;
                x.save();
                //x.dirty = true;
              })
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
          )
        )
        , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
          , React.createElement(Checkbox, {
            children: "Überspannungsschaden",
            disabled: state.disabled,
            checked: x.local_overvoltageDamage,
            onChange: (e) =>
              runInAction(() => {
                x.local_overvoltageDamage = e.target.checked;
                x.save();
                //x.dirty = true;
              })
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
          )
        )

        , React.createElement(Form.Item, { label: "Erledigungstext", __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
          , React.createElement(Space, { direction: "vertical", style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
            , !state.disabled && editFinishedText && (
              React.createElement(Space, { style: { float: "right" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
                , React.createElement(Button, {
                  children: React.createElement(Icon, { icon: "fa:undo", __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}} ),
                  //disabled={!x.dirty || state.disabled}
                  onClick: () => {
                    setEditFinishedText(false);
                    x.reset();
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
                )
                , React.createElement(Button, {
                  type: "primary",
                  children: React.createElement(Icon, { icon: "fa:save", __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}} ),
                  //disabled={!x.dirty || state.disabled}
                  onClick: () => {
                    setEditFinishedText(false);
                    x.save();
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
                )
              )
            )
            , React.createElement(Input.TextArea, {
              disabled: state.disabled || !editFinishedText,
              value: x.finishedText,
              onChange: (e) => x.setFinishedText(e.target.value),
              autoSize: { minRows: 6 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
            )
            , !state.disabled && editFinishedText && (
              React.createElement(Button, {
                disabled: state.disabled,
                children: "Entwurf einfügen" ,
                onClick: () => x.setInstertTemplate("finishedText"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}
              )
            )
            , !state.disabled && !editFinishedText && (
              React.createElement(Button, {
                disabled: state.disabled,
                children: "Bearbeiten",
                onClick: () => setEditFinishedText(true), __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}
              )
            )
          )
        )
        , React.createElement(Form.Item, { label: "Bemerkungen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}
          , React.createElement(Space, { direction: "vertical", style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
            , !state.disabled && editDocumentText && (
              React.createElement(Space, { style: { float: "right" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}
                , React.createElement(Button, {
                  children: React.createElement(Icon, { icon: "fa:undo", __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}} ),
                  //disabled={!x.dirty || state.disabled}
                  onClick: () => {
                    setEditDocumentText(false);
                    x.reset();
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}
                )
                , React.createElement(Button, {
                  type: "primary",
                  children: React.createElement(Icon, { icon: "fa:save", __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}} ),
                  //disabled={!x.dirty || state.disabled}
                  onClick: () => {
                    setEditDocumentText(false);
                    x.save();
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}
                )
              )
            )
            , React.createElement(Input.TextArea, {
              disabled: state.disabled || !editDocumentText,
              value: x.documentText,
              onChange: (e) => x.setDocumentText(e.target.value),
              autoSize: { minRows: 6 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 144}}
            )
            , !state.disabled && editDocumentText && (
              React.createElement(Button, {
                disabled: state.disabled,
                children: "Entwurf einfügen" ,
                onClick: () => x.setInstertTemplate("documentText"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
              )
            )
            , !state.disabled && !editDocumentText && (
              React.createElement(Button, {
                disabled: state.disabled,
                children: "Bearbeiten",
                onClick: () => setEditDocumentText(true), __self: this, __source: {fileName: _jsxFileName, lineNumber: 158}}
              )
            )
          )
        )
      )

      , React.createElement(Drawer, {
        title: "Textvorlage einfügen" ,
        placement: "bottom",
        open: !!x.insertTemplate,
        onClose: () => x.setInstertTemplate(null), __self: this, __source: {fileName: _jsxFileName, lineNumber: 168}}

        , React.createElement(List, {
          dataSource: state.templates,
          renderItem: (template) => (
            React.createElement(List.Item, {
              actions: [React.createElement(Icon, { icon: "fa:paste", __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}} )],
              onClick: () => x.instertTemplateText(template.text),
              style: { cursor: "pointer" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 177}}

              , React.createElement(List.Item.Meta, {
                title: template.title,
                description: template.text, __self: this, __source: {fileName: _jsxFileName, lineNumber: 182}}
              )
            )
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 174}}
        )
      )
    )
  );
});
